<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        
        <!--Dashboard-->
        <sidebar-item
          true
          :link="{
            name: 'Dashboard',
            icon: 'fas fa-chart-line',
            path: '/dashboard',
          }"
        >
        </sidebar-item>

        <!--Cadastro-->
        <sidebar-item
          opened
          v-if="
            permissions.includes('view imovels') || 
            permissions.includes('view contratos') || 
            permissions.includes('view clientes') 
          "
          true
          :link="{
            name: 'Cadastro',
            icon: 'fas fa-table-list',
          }"
          ><sidebar-item
            v-if="permissions.includes('view clientes')"
            :link="{
              name: 'Clientes',
              path: '/app/Cliente-management/list-Cliente',
            }"
          ></sidebar-item
          ><sidebar-item
            v-if="permissions.includes('view imovels')"
            :link="{
              name: 'Imóveis',
              path: '/app/Imovel-management/list-Imovel',
            }"
          ></sidebar-item
          ><sidebar-item
            v-if="permissions.includes('view contratos')"
            :link="{
              name: 'Locações',
              path: '/app/Contrato-management/list-Contrato',
            }"
          ></sidebar-item></sidebar-item
        >

        
          
        <!--Seguro Incêndio-->
        <sidebar-item
          opened
          v-if="
            permissions.includes('view seguroincendios')
          "
          true
          :link="{
            name: 'Seguro Incêndio',
            icon: 'fas fa-house-fire',
          }"
          >
          <sidebar-item
            :link="{
              name: 'Orçamentos',
              path: '/app/Seguroincendio-management/list-Seguroincendio',
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Apólices',
              path: '/app/Seguroincendio-management/list-Seguroincendio-apolice',
            }"
          >
          </sidebar-item>
          </sidebar-item>
<!--Financeiro-->
        <sidebar-item
          v-if="
            permissions.includes('view faturas') 
            || permissions.includes('view faturaextratos') 
          "
          true
          :link="{
            name: 'Financeiro',
            icon: 'fas fa-credit-card',
          }"
          >
            <sidebar-item
              v-if="permissions.includes('view faturas')"
              :link="{
                name: 'Fatura',
                path: '/app/Fatura-management/list-Fatura',
              }"
            ></sidebar-item
            ><sidebar-item
              v-if="permissions.includes('view faturaextratos')"
              :link="{
                name: 'Fatura Extrato',
                path: '/app/Faturaextrato-management/list-Faturaextrato',
              }"
            ></sidebar-item>
        </sidebar-item>
        <!--Configurações-->
        <sidebar-item
          :link="{
            name: 'Configurações',
            icon: 'fas fa-sliders',
          }"
        >
          <sidebar-item
            :link="{ name: 'Meu perfil', path: '/app/user-profile' }"
          />

          <sidebar-item
            v-if="permissions.includes('view users')"
            :link="{
              name: 'Cadastro de Usuários',
              path: '/app/user-management/list-users',
            }"
          />

          <sidebar-item
            v-if="permissions.includes('edit permissions')"
            :link="{
              name: 'Permissões',
              path: '/app/role-management/list-roles',
            }"
          />

          <sidebar-item
            v-if="roles.includes('admin')"
            :link="{
              name: 'Meu perfil',
              path: '',
            }"
          />
          
          <sidebar-item
            v-if="permissions.includes('edit preferencias')"
            :link="{
              name: 'Preferências',
              path: '/app/Preferencia-management/edit-Preferencia/0',
            }"
          ></sidebar-item>
        </sidebar-item>

        <!--Labels-->
          <sidebar-item
          v-if="false"
          true
          :link="{
            name: 'Labels',
            icon: 'ni ni-shop text-primary',
          }"
          ><sidebar-item
            :link="{
              name: 'API request name',
              path: '/app/Requesttipo-management/list-Requesttipo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Numero de Parcelas',
              path: '/app/Seguroiprevnumeroparcela-management/list-Seguroiprevnumeroparcela',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'API Requests',
              path: '/app/Apirequest-management/list-Apirequest',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Contratar Seguro Incendio',
              path: '/app/Seguroicontratar-management/list-Seguroicontratar',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Cancelar Seguro Incêndio',
              path: '/app/Seguroicancelar-management/list-Seguroicancelar',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Dashboard',
              path: '/app/Dashboard-management/list-Dashboard',
            }"
          ></sidebar-item
          >
          <sidebar-item
            :link="{
              name: 'Preferências',
              path: '/app/Preferencia-management/list-Preferencia',
            }"
          ></sidebar-item>
          
          </sidebar-item
        >
        
        

        <!--Sistema-->
        <sidebar-item
          v-if="false"
          true
          :link="{
            name: 'Sistema',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Status de apolices',
              path: '/app/Apolicestatu-management/list-Apolicestatu',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Seguradoras',
              path: '/app/Seguradora-management/list-Seguradora',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Tipo de Logradouro',
              path: '/app/Logradourotipo-management/list-Logradourotipo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Tipo de contrato',
              path: '/app/Tipocontrato-management/list-Tipocontrato',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Tipo Pessoa',
              path: '/app/Tipopessoa-management/list-Tipopessoa',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Clausula serviços',
              path: '/app/Clausulaservico-management/list-Clausulaservico',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Status do Seguro Incendio',
              path: '/app/Seguroistatu-management/list-Seguroistatu',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Situação Contrato',
              path: '/app/Situcontrato-management/list-Situcontrato',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Lista de Municipios',
              path: '/app/Municipio-management/list-Municipio',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Tipo de telefone',
              path: '/app/Tipotelefone-management/list-Tipotelefone',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Formas de pagamento',
              path: '/app/Seguroiformapagto-management/list-Seguroiformapagto',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Estado Civil',
              path: '/app/Estadocivil-management/list-Estadocivil',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Sexo',
              path: '/app/Pessoasexo-management/list-Pessoasexo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Vinculo Cliente',
              path: '/app/Pessoavinculo-management/list-Pessoavinculo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Tipo de Empresa',
              path: '/app/Pessoajuridicatipo-management/list-Pessoajuridicatipo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'tipo de Imóvel',
              path: '/app/Imoveltipo-management/list-Imoveltipo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Método de calculo',
              path: '/app/Metodocalculo-management/list-Metodocalculo',
            }"
          ></sidebar-item
          ><sidebar-item
            :link="{
              name: 'Opções de parcelamento cotado',
              path: '/app/Seguroiparcelamento-management/list-Seguroiparcelamento',
            }"
          ></sidebar-item
        ></sidebar-item>

        

        <hr
          class="my-3"
          style="
            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
            border-style: solid none none;
            border-width: 1px 0px 0px;
            border-image: none 100% / 1 / 0 stretch;
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0px;
          "
        />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import Vuex from "vuex";


export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition
  },
  data() {
    return {
      roles: [],
      permissions: [],
      user:[],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    async carregaPerfil(){
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };

      //atualiza perfil no tawkto
      this.$initializeTawkTo({
        user: {
          userId : this.user.tawkto_id,
          name: this.user.name+' | '+this.user.imobiliaria,
          email: this.user.email,
          hash: this.user.tawkto_hash,
          organization: this.user.imobiliaria
      }});
      
    },
  },
  mounted() {
    this.initScrollbar();
    this.carregaPerfil();    
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    me: {
      handler: function (val) {
        this.roles = val.roles.map((r) => r.name);
        this.permissions = val.roles.flatMap((role) => 
          role.permissions ? role.permissions.map(permission => permission.name) : []
        );
        //console.log('permissions', this.permissions);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}
body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}
</style>
