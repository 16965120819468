import service from '@/store/services/preferencias-service';

const state = {
  list: {},
  preferencia: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, preferencia) => {
    state.preferencia = preferencia;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_INTEGRACAO: (state, integracao) => {
    state.integracao = integracao;
  },
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((preferencia) => { commit('SET_RESOURCE', preferencia); });
  },

  baixarArquivo({ commit, dispatch }, params) {
    // Chama a função do serviço para baixar o PDF
      return service.downloadPDF(params[0],params[1])
        .then((resposta) => {
          // Manipular a resposta, se necessário
          //console.log("PDF baixado com sucesso!", resposta);
          return resposta;
        })
        .catch((erro) => {
          // Lidar com erros, como usuário não autenticado
          console.error("Erro ao baixar a apólice:", erro);
          throw erro;
        });
  },

  integracao({ commit, dispatch }, params){
    return service.integracao(params)
      .then((integracao) => { commit('SET_INTEGRACAO', integracao); });
  },
  getintegracao({ commit, dispatch }){
    return service.getintegracao()
    .then((integracao) => { commit('SET_INTEGRACAO', integracao); });
  },
  add({commit, dispatch}, params) {
    return service.add(params)
      .then((preferencia) => { commit('SET_RESOURCE', preferencia); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((preferencia) => { commit('SET_RESOURCE', preferencia); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  preferencia: state => state.preferencia,
  integracao: state => state.integracao,
  dropdown: (state) => {
    return state.list.map(preferencia => ({
      id: preferencia.id,
      name: preferencia.name,
      clausulaservico_id: preferencia.clausulaservico?.id,
      clausulaservicoobrigatorio: preferencia.clausulaservicoobrigatorio,
      descontopercentualmin: preferencia.descontopercentualmin,
      descontopercentualmax: preferencia.descontopercentualmax,
      descontopercentualpad: preferencia.descontopercentualpad,
      seguroiprevnumeroparcela_id: preferencia.seguroiprevnumeroparcela?.id,
      seguroiprevnumeroparcelaobrigatorio: preferencia.seguroiprevnumeroparcelaobrigatorio,
      autocontratacao: preferencia.autocontratacao,
      autocontratacaoobrigatorio: preferencia.autocontratacaoobrigatorio
    }));
  }
};

const preferencias = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default preferencias;
