import service from '@/store/services/metodocalculos-service';

const state = {
  list: {},
  metodocalculo: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, metodocalculo) => {
    state.metodocalculo = metodocalculo;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((metodocalculo) => { commit('SET_RESOURCE', metodocalculo); });
  },

  baixarArquivo({ commit, dispatch }, params) {
    // Chama a função do serviço para baixar o PDF
      return service.downloadPDF(params[0],params[1])
        .then((resposta) => {
          // Manipular a resposta, se necessário
          //console.log("PDF baixado com sucesso!", resposta);
          return resposta;
        })
        .catch((erro) => {
          // Lidar com erros, como usuário não autenticado
          console.error("Erro ao baixar a apólice:", erro);
          throw erro;
        });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((metodocalculo) => { commit('SET_RESOURCE', metodocalculo); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((metodocalculo) => { commit('SET_RESOURCE', metodocalculo); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  metodocalculo: state => state.metodocalculo,
  dropdown: (state) => {
    return state.list.map(metodocalculo => ({
      id: metodocalculo.id,
      name: metodocalculo.name,
      imoveltipos: metodocalculo.imoveltipos,
      formulaincendio: metodocalculo.formulaincendio,
      formulacivil: metodocalculo.formulacivil,
      formulaconteudo: metodocalculo.formulaconteudo,
      formulaeletrico: metodocalculo.formulaeletrico,
      formulaimpacto: metodocalculo.formulaimpacto,
      formulaaluguel: metodocalculo.formulaaluguel,
      formulavendaval: metodocalculo.formulavendaval,
      formulatumulto: metodocalculo.formulatumulto
    }));
  }
};

const metodocalculos = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default metodocalculos;
