<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h2 class="mb-0 ml-4">Editar Preferências</h2>
              </div>
              <div class="col-4 text-right" v-if="!isInsideModal">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Voltar para lista</base-button
                >
              </div>
            </div>
            <div class="card-body"></div>
            <div class="card-body">
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom">Seguro incêndio</h3>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input label="Serviços de Assistência 24h">
                    <el-select
                      name="clausulaservico"
                      ref="clausulaservicoInput"
                      :tabindex="0"
                      v-model="preferencia.clausulaservico.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                    >
                      <el-option
                        v-for="single_clausulaservico in all_clausulaservicos"
                        :key="single_clausulaservico.id"
                        :value="single_clausulaservico.id"
                        :label="
                          preferencia &&
                          preferencia.clausulaservico &&
                          single_clausulaservico.id ===
                            preferencia.clausulaservico.id
                            ? `★ ${single_clausulaservico.name}`
                            : single_clausulaservico.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.clausulaservico"
                  />
                  <div ref="display_clausulaservico"></div>
                </div>
                <div class="col-md-4">
                  <base-input label="Obrigatoriamente?">
                    <base-switch
                      on-text="Sim"
                      off-text="Não"
                      class="mr-1"
                      v-model="preferencia.clausulaservicoobrigatorio"
                      ref="clausulaservicoobrigatorioInput"
                    ></base-switch>
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="descontopercentualminInput"
                    label="Percentual de Desconto Minimo"
                    prepend-icon="fas fa-percent"
                    v-model="descontopercentualminFormatado"
                  />

                  <validation-error
                    :errors="apiValidationErrors.descontopercentualmin"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="descontopercentualmaxInput"
                    label="Percentual de Desconto Máximo"
                    prepend-icon="fas fa-percent"
                    v-model="descontopercentualmaxFormatado"
                  />

                  <validation-error
                    :errors="apiValidationErrors.descontopercentualmax"
                  />
                </div>
                <div class="col-md-4">
                  <base-input
                    :tabindex="0"
                    ref="descontopercentualpadInput"
                    label="Percentual de Desconto Padrão"
                    prepend-icon="fas fa-percent"
                    v-model="descontopercentualpadFormatado"
                  />

                  <validation-error
                    :errors="apiValidationErrors.descontopercentualpad"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input label="Numero de Parcelas">
                    <el-select
                      name="seguroiprevnumeroparcela"
                      ref="seguroiprevnumeroparcelaInput"
                      :tabindex="0"
                      v-model="preferencia.seguroiprevnumeroparcela.id"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="Selecione..."
                    >
                      <el-option
                        v-for="single_seguroiprevnumeroparcela in all_seguroiprevnumeroparcelas"
                        :key="single_seguroiprevnumeroparcela.id"
                        :value="single_seguroiprevnumeroparcela.id"
                        :label="
                          preferencia &&
                          preferencia.seguroiprevnumeroparcela &&
                          single_seguroiprevnumeroparcela.id ===
                            preferencia.seguroiprevnumeroparcela.id
                            ? `★ ${single_seguroiprevnumeroparcela.name}`
                            : single_seguroiprevnumeroparcela.name
                        "
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.seguroiprevnumeroparcela"
                  />
                  <div ref="display_seguroiprevnumeroparcela"></div>
                </div>
                <div class="col-md-4">
                  <base-input label="Obrigatoriamente?">
                    <base-switch
                      on-text="Sim"
                      off-text="Não"
                      class="mr-1"
                      v-model="preferencia.seguroiprevnumeroparcelaobrigatorio"
                      ref="seguroiprevnumeroparcelaobrigatorioInput"
                    ></base-switch>
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input label="Contratar automaticamente?">
                    <base-switch
                      on-text="Sim"
                      off-text="Não"
                      class="mr-1"
                      v-model="preferencia.autocontratacao"
                      ref="autocontratacaoInput"
                    ></base-switch>
                  </base-input>
                </div>
                <div class="col-md-4">
                  <base-input label="Obrigatoriamente?">
                    <base-switch
                      on-text="Sim"
                      off-text="Não"
                      class="mr-1"
                      v-model="preferencia.autocontratacaoobrigatorio"
                      ref="autocontratacaoobrigatorioInput"
                    ></base-switch>
                  </base-input>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-4">
                  <base-input label="Municipios de Preferência">
                    <el-select
                      v-model="municipios"
                      multiple
                      filterable
                      placeholder="Selecione..."
                    >
                      <el-option
                        v-for="single_municipio in all_municipios"
                        :key="single_municipio.id"
                        :value="single_municipio.id"
                        :label="single_municipio.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.municipio"
                  />
                </div>
              </div>
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom"></h3>
              </div>
              <div class="row"></div>

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Salvar Alterações
                </base-button>
              </div>
            </div>
          </form>
        </card>

        <!--integração-->
        <card v-if="integracao.erp == 1 || integracao.erp == 2">
          <form @submit.prevent="handleSubmitIntegracao">
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h2 class="mb-0 ml-4">Integração com ERP</h2>
                <h4 class="mb-0 ml-4"><b>Sistema: </b> 
                    <class v-if="integracao.erp==1">Superlogica</class>
                    <class v-if="integracao.erp==2">Imoview</class>
                </h4>
              </div>
              
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <p></p>
                </div>
              </div>

              <div class="row"
                v-if="integracao.erp_codigousuario != null || integracao.erp_seguradora_id != null"
              >
                <div class="col-md-8">
                  <h2>CONECTADO</h2>
                  <small v-if="integracao.erp == 2">
                    Fase: Homologando acesso... Aguarde.

                  </small>
                </div>
              </div>

              <div class="row" 
                v-if="integracao.erp_codigousuario == null && integracao.erp_seguradora_id == null"
              >
                <div class="col-md-8">
                  <base-input
                    :tabindex="0"
                    ref="erp_licencaInput"
                    label="Codigo da Licença"
                    prepend-icon="fas fa-gears"
                    v-model="integracao.erp_licenca"
                  />

                  <validation-error
                    :errors="apiValidationErrors.erp_licenca"
                  />
                      
                  <div class="col-12 mt--4">
                    <small class="mb-0 ml-0">
                      <a v-if="integracao.erp==1" href="https://imobiliarias.superlogica.com/hc/pt-br/articles/360043554713-Como-localizar-sua-licen%C3%A7a-Superl%C3%B3gica">Veja como encontrar o código da sua Licença</a>
                      <a v-if="integracao.erp==2" href="https://ajuda.universalsoftware.com.br/artigo/chave-integracao-api-imoview-crm">Veja como encontrar o código da sua Licença</a>
                    </small>
                  </div>
                </div>
              </div>


              <div class="row mt-4"
                v-if="integracao.erp_codigousuario == null && integracao.erp_seguradora_id == null"
              >
                <div class="col-md-4"
                    v-if="integracao.erp==2"
                >
                  <base-input
                    :tabindex="0"
                    ref="erp_email"
                    label="Email"
                    prepend-icon="fas fa-gears"
                    v-model="integracao.erp_email"
                  />

                  <validation-error
                    :errors="apiValidationErrors.erp_email"
                  />
                </div>  
                <div class="col-md-4"
                    v-if="integracao.erp==2"
                >
                  <base-input
                    type="password"
                    :tabindex="0"
                    ref="erp_pw"
                    label="Senha"
                    prepend-icon="fas fa-gears"
                    v-model="integracao.erp_pw"
                  />

                  <validation-error
                    :errors="apiValidationErrors.erp_email"
                  />
                </div>                
              </div>
              <div class="my-4"
                v-if="integracao.erp_codigousuario == null && integracao.erp_seguradora_id == null"
              >
                <base-button
                  type="button"
                  class="mt-5 btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="
                      isSubmittingIntegracao
                      || integracao.erp_licenca == ''
                    "
                >
                  Conectar
                </base-button>
                
              </div>

            </div>
          </form>
        </card>
        <!--fim intgração-->
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from "@/components/BaseSlider";
import Modal from "@/components/Modal.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from "vue-the-mask";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    insideModalOnSave: {
      type: String,
      required: false,
      default: null,
    },
  },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,
      isSubmittingIntegracao: false,


      all_municipios: [],
      all_clausulaservicos: [],
      all_seguroiprevnumeroparcelas: [],
      municipios:[],
      erp_licenca:null,
      preferencia: {
        type: "preferencias",

        clausulaservicoobrigatorio: "",
        descontopercentualmin: "",
        descontopercentualmax: "",
        descontopercentualpad: "",
        seguroiprevnumeroparcelaobrigatorio: "",
        autocontratacao: "",
        autocontratacaoobrigatorio: "",

        municipios: [],
        clausulaservico: {
          type: "clausulaservicos",
          id: null,
        },
        seguroiprevnumeroparcela: {
          type: "seguroiprevnumeroparcelas",
          id: null,
        },
        relationshipNames: ["clausulaservico", "seguroiprevnumeroparcela", "municipios"],
      },

      integracao:{
        type: "licensa",
        erp: null,
        erp_licenca:null,
        erp_pw:null,
        erp_email:null
      }
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["clausulaservico","seguroiprevnumeroparcela"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    descontopercentualminFormatado: {
      get() {
        return this.numToBr(this.preferencia.descontopercentualmin);
      },
      set(valor) {
        this.preferencia.descontopercentualmin = this.numToUs(valor);
      },
    },
    descontopercentualmaxFormatado: {
      get() {
        return this.numToBr(this.preferencia.descontopercentualmax);
      },
      set(valor) {
        this.preferencia.descontopercentualmax = this.numToUs(valor);
      },
    },
    descontopercentualpadFormatado: {
      get() {
        return this.numToBr(this.preferencia.descontopercentualpad);
      },
      set(valor) {
        this.preferencia.descontopercentualpad = this.numToUs(valor);
      },
    },
  },
  watch: {},
  created() {
    this.get();

    this.getClausulaservico();
    this.getSeguroiprevnumeroparcela();
    this.getMunicipios();
    this.getIntegracao();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        if (id == 0) {
          //id indefinido
          let params = {
            page: {
              number: 1,
              size: 1,
            },
            include: "municipios,clausulaservico,seguroiprevnumeroparcela",
          };
          await this.$store.dispatch("preferencias/list", params).then(() => {
            let apiData = this.$store.getters["preferencias/list"][0];
            for (let key in apiData) {
              if (apiData[key] === null && this.preferencia[key]?.id == null) {
                apiData[key] = this.preferencia[key];
              }
            }
            this.preferencia = apiData;
            
            this.preferencia.municipios.forEach((municipio) => {
                this.municipios.push(municipio.id+"");
            });
          });
        } else {
          //id definido
          await this.$store.dispatch("preferencias/get", id);
          let apiData = this.$store.getters["preferencias/preferencia"];
          for (let key in apiData) {
            if (apiData[key] === null && this.preferencia[key]?.id == null) {
              apiData[key] = this.preferencia[key];
            }
          }
          this.preferencia = apiData;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getMunicipios(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            
          },
        };
        //if(this.all_proprietarios || Object.keys(this.all_proprietarios).lenght == undefined){
        await this.$store.dispatch("municipios/list", params);
        this.all_municipios = await this.$store.getters["municipios/dropdown"];
        //}

        if (this.all_municipios && this.all_municipios.length) {
          // this.imovel.proprietarios.id = this.all_proprietarios[0].id;
          if (idn !== null) {
            this.all_municipios.push(idn + "");
            this.$refs.all_municipiosInput.$emit("change", idn);
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getIntegracao(){
      await this.$store.dispatch("preferencias/getintegracao");
          let apiData = this.$store.getters["preferencias/integracao"];
          this.integracao = apiData;
          console.log('integra', this.integracao);
    },
    async getClausulaservico(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.clausulaservicos_filter_itens
              ? this.clausulaservicos_filter_itens
              : {}),
          },
        };
        //if(this.all_clausulaservicos || Object.keys(this.all_clausulaservicos).lenght == undefined){
        await this.$store.dispatch("clausulaservicos/list", params);
        this.all_clausulaservicos = await this.$store.getters[
          "clausulaservicos/dropdown"
        ];
        //}

        if (this.all_clausulaservicos && this.all_clausulaservicos.length) {
          // this.preferencia.clausulaservico.id = this.all_clausulaservicos[0].id;
          if (idn !== null) {
            this.preferencia.clausulaservico.id = idn;
            this.$refs.clausulaservicoInput.$emit(
              "change",
              this.preferencia.clausulaservico.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getSeguroiprevnumeroparcela(idn = null) {
      try {
        //filter_route
        let params = {
          filter: {
            ...(this.seguroiprevnumeroparcelas_filter_itens
              ? this.seguroiprevnumeroparcelas_filter_itens
              : {}),
          },
        };
        //if(this.all_seguroiprevnumeroparcelas || Object.keys(this.all_seguroiprevnumeroparcelas).lenght == undefined){
        await this.$store.dispatch("seguroiprevnumeroparcelas/list", params);
        this.all_seguroiprevnumeroparcelas = await this.$store.getters[
          "seguroiprevnumeroparcelas/dropdown"
        ];
        //}

        if (
          this.all_seguroiprevnumeroparcelas &&
          this.all_seguroiprevnumeroparcelas.length
        ) {
          // this.preferencia.seguroiprevnumeroparcela.id = this.all_seguroiprevnumeroparcelas[0].id;
          if (idn !== null) {
            this.preferencia.seguroiprevnumeroparcela.id = idn;
            this.$refs.seguroiprevnumeroparcelaInput.$emit(
              "change",
              this.preferencia.seguroiprevnumeroparcela.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
      // Verifica se o valor termina com um ponto
      let sulfix = "";
      if (valor.toString().endsWith(".")) {
        sulfix = ",";
      }

      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split(".")[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais,
      });
      return valorFormatado.toString() + sulfix;
    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
      let valorFormatado = valorBrasileiro
        .replace(/\./g, "")
        .replace(/,/g, ".");
      // Verifica se o último caractere é uma vírgula
      return valorFormatado;
    },

    goBack() {
      this.$router.push({ name: "Edit Preferencia", params: { id: '0' } });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }

      //this.preferencia.excerpt = this.preferencia.description;
      /**
      this.preferencia.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.preferencia.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */

      try {
        var dados_enviar = Object.assign({}, this.preferencia);

        if (dados_enviar.clausulaservico?.id == null) {
          delete dados_enviar.clausulaservico;
        }
        if (dados_enviar.seguroiprevnumeroparcela?.id == null) {
          delete dados_enviar.seguroiprevnumeroparcela;
        }

        if (dados_enviar.created_at) {
          delete dados_enviar.created_at;
        }
        if (dados_enviar.updated_at) {
          delete dados_enviar.updated_at;
        }
        if (dados_enviar.deleted_at) {
          delete dados_enviar.deleted_at;
        }
        if (dados_enviar.tenants) {
          delete dados_enviar.tenants;
        }
        dados_enviar.municipios = [];
        this.municipios.forEach((municipio) => {
          dados_enviar.municipios.push({
            id: municipio,
            type: "municipios",
          });
        });

        if (!this.$route.params.id) {
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("preferencias/add", dados_enviar);
          this.preferencia = await this.$store.getters[
            "preferencias/preferencia"
          ];
        } else {
          //atualizar registro
          await this.$store.dispatch("preferencias/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("preferencias/upload", {
            preferencia: this.preferencia,
            image: this.file,
          });

          this.preferencia.image = await this.$store.getters[
            "preferencias/url"
          ];

          await this.$store.dispatch("preferencias/update", this.preferencia);
          this.preferencia = await this.$store.getters[
            "preferencias/Preferencia"
          ];
        }

        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Preferências Salvas com sucesso.",
        });

        if (!this.isInsideModal) {
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        } else {
          //fecha modal
          this.$emit("close-modal");
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if (this.insideModalOnSave !== null) {
            this.$emit("callFunction", {
              functionName: this.insideModalOnSave,
              args: [this.preferencia.id],
            });
          }
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.preferencia.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch(
            "preferencias/destroy",
            this.preferencia.id
          );
        }

        this.preferencia.date_at = new Date();
      }
    },

    async handleSubmitIntegracao() {
      this.isSubmittingIntegracao = true;
      try {
        //atualizar registro
        await this.$store.dispatch("preferencias/integracao", this.integracao);
        this.isSubmittingIntegracao = false;
      } catch (error) {
        this.isSubmittingIntegracao = false;
        this.$notify({
          type: "danger",
          message: "Oops, Algo deu errado!",
        });
        this.setApiValidation(error.response.data.errors);
      }

    },
    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },

  mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll("span");

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute("tabindex");
    });

    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
