import service from '@/store/services/seguroincendios-service';

const state = {
  list: {},
  seguroincendio: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, seguroincendio) => {
    state.seguroincendio = seguroincendio;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((seguroincendio) => { commit('SET_RESOURCE', seguroincendio); });
  },

  baixarArquivo({ commit, dispatch }, params) {
    // Chama a função do serviço para baixar o PDF
      return service.downloadPDF(params[0],params[1])
        .then((resposta) => {
          // Manipular a resposta, se necessário
          //console.log("PDF baixado com sucesso!", resposta);
          return resposta;
        })
        .catch((erro) => {
          // Lidar com erros, como usuário não autenticado
          console.error("Erro ao baixar a apólice:", erro);
          throw erro;
        });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((seguroincendio) => { commit('SET_RESOURCE', seguroincendio); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((seguroincendio) => { commit('SET_RESOURCE', seguroincendio); });
  },
  updatedata({commit, dispatch}, params) {
    return service.updatedata(params)
      .then((seguroincendio) => { commit('SET_RESOURCE', seguroincendio); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  seguroincendio: state => state.seguroincendio,
  dropdown: (state) => {
    return state.list.map(seguroincendio => ({
      id: seguroincendio.id,
      name: seguroincendio.imovel_id,
      contrato_id: seguroincendio.contrato?.id,
      datainicio: seguroincendio.datainicio,
      clausulaservico_id: seguroincendio.clausulaservico?.id,
      valorlocacao: seguroincendio.valorlocacao,
      areaconstruida: seguroincendio.areaconstruida,
      metodocalculo_id: seguroincendio.metodocalculo?.id,
      descontopercentual: seguroincendio.descontopercentual,
      seguroiprevnumeroparcela_id: seguroincendio.seguroiprevnumeroparcela?.id,
      coberturaincendio: seguroincendio.coberturaincendio,
      coberturacivil: seguroincendio.coberturacivil,
      coberturaconteudo: seguroincendio.coberturaconteudo,
      coberturaeletrico: seguroincendio.coberturaeletrico,
      coberturaimpacto: seguroincendio.coberturaimpacto,
      coberturaaluguel: seguroincendio.coberturaaluguel,
      coberturavendaval: seguroincendio.coberturavendaval,
      coberturatumulto: seguroincendio.coberturatumulto,
      autocontratacao: seguroincendio.autocontratacao,
      valorestimado: seguroincendio.valorestimado
    }));
  }
};

const seguroincendios = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default seguroincendios;
