var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-8"},[_c('h2',{staticClass:"mb-0 ml-4"},[_vm._v("Editar Orçamento")])]),(!_vm.isInsideModal)?_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":_vm.goBack}},[_vm._v("Voltar para lista")])],1):_vm._e()]),_c('div',{staticClass:"card-body"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[_c('h3',{staticClass:"border-bottom"},[_vm._v("Dados Gerais")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Locação"}},[_c('el-select',{ref:"contratoInput",attrs:{"name":"contrato","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione..."},on:{"change":function($event){_vm.exibedadoscontrato();
                      _vm.seguroincendio.coberturaincendio = 0;
                      _vm.calculacobertura();}},model:{value:(_vm.seguroincendio.contrato.id),callback:function ($$v) {_vm.$set(_vm.seguroincendio.contrato, "id", $$v)},expression:"seguroincendio.contrato.id"}},_vm._l((_vm.all_contratos),function(single_contrato){return _c('el-option',{key:single_contrato.id,attrs:{"value":single_contrato.id,"label":_vm.preferencia &&
                        _vm.preferencia.contrato &&
                        single_contrato.id === _vm.preferencia.contrato.id
                          ? ("★ " + (single_contrato.name))
                          : single_contrato.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.contrato}}),_c('div',{ref:"display_contrato"})],1),_c('div',{staticClass:"input-group-append"},[_c('base-button',{staticStyle:{"margin":"30px 0px 25px 0px","padding":"0"},attrs:{"icon":"","type":"primary"},on:{"click":function($event){_vm.isModalContratoVisible = true}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-fat-add"})])])],1)]),_c('div',{staticClass:"row"},[_c('blockquote',{ref:"display_contrato",staticStyle:{"padding":"0","padding-left":"10px","margin-left":"20px","border-left":"3px solid #777"}})]),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[(_vm.seguroincendio.contrato.id !== null)?_c('h3',{staticClass:"border-bottom"},[_vm._v(" Parâmetros do Seguro ")]):_vm._e()]),(_vm.seguroincendio.contrato.id !== null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Data inicial"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"tabindex":0,"config":{
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      altInput: true,
                      altFormat: 'd/m/Y',
                    }},model:{value:(_vm.seguroincendio.datainicio),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "datainicio", $$v)},expression:"seguroincendio.datainicio"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.datainicio}})],1)]):_vm._e(),(_vm.seguroincendio.contrato.id !== null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Serviços de Assistência 24h"}},[_c('el-select',{ref:"clausulaservicoInput",attrs:{"name":"clausulaservico","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione..."},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.seguroincendio.clausulaservico.id),callback:function ($$v) {_vm.$set(_vm.seguroincendio.clausulaservico, "id", $$v)},expression:"seguroincendio.clausulaservico.id"}},_vm._l((_vm.all_clausulaservicos),function(single_clausulaservico){return _c('el-option',{key:single_clausulaservico.id,attrs:{"value":single_clausulaservico.id,"label":_vm.preferencia &&
                        _vm.preferencia.clausulaservico &&
                        single_clausulaservico.id ===
                          _vm.preferencia.clausulaservico.id
                          ? ("★ " + (single_clausulaservico.name))
                          : single_clausulaservico.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.clausulaservico}}),_c('div',{ref:"display_clausulaservico"})],1)]):_vm._e(),(_vm.seguroincendio.contrato.id !== null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"valorlocacaoInput",attrs:{"tabindex":0,"label":"Valor do aluguel","prepend-icon":"fa fa-square-o"},on:{"change":function($event){_vm.seguroincendio.coberturaincendio = 0;
                    _vm.calculacobertura();
                    _vm.calculaparcela();}},model:{value:(_vm.valorlocacaoFormatado),callback:function ($$v) {_vm.valorlocacaoFormatado=$$v},expression:"valorlocacaoFormatado"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.valorlocacao}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"areaconstruidaInput",attrs:{"tabindex":0,"label":"Área Construida","prepend-icon":"fa fa-square-o"},on:{"change":function($event){_vm.seguroincendio.coberturaincendio = 0;
                    _vm.calculacobertura();
                    _vm.calculaparcela();}},model:{value:(_vm.areaconstruidaFormatado),callback:function ($$v) {_vm.areaconstruidaFormatado=$$v},expression:"areaconstruidaFormatado"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.areaconstruida}})],1),_c('div',{staticClass:"col-md-4"},[(_vm.seguroincendio.areaconstruida > 0)?_c('base-input',{attrs:{"label":"Método de cálculo das Coberturas","disabled":_vm.seguroincendio.valorlocacao < 400 ||
                    _vm.seguroincendio.areaconstruida < 40 ||
                    _vm.seguroincendio.contrato.id < 0}},[(_vm.seguroincendio.areaconstruida > 0)?_c('el-select',{ref:"metodocalculoInput",attrs:{"name":"metodocalculo","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":_vm.seguroincendio.valorlocacao < 400 ||
                      _vm.seguroincendio.areaconstruida < 40 ||
                      _vm.seguroincendio.contrato.id < 0},on:{"change":function($event){_vm.seguroincendio.coberturaincendio = 0;
                      _vm.calculacobertura();
                      _vm.calculaparcela();}},model:{value:(_vm.seguroincendio.metodocalculo.id),callback:function ($$v) {_vm.$set(_vm.seguroincendio.metodocalculo, "id", $$v)},expression:"seguroincendio.metodocalculo.id"}},_vm._l((_vm.all_metodocalculos),function(single_metodocalculo){return _c('el-option',{key:single_metodocalculo.id,attrs:{"value":single_metodocalculo.id,"label":_vm.preferencia &&
                        _vm.preferencia.metodocalculo &&
                        single_metodocalculo.id ===
                          _vm.preferencia.metodocalculo.id
                          ? ("★ " + (single_metodocalculo.name))
                          : single_metodocalculo.name}})}),1):_vm._e()],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.metodocalculo}}),_c('div',{ref:"display_metodocalculo"})],1)]):_vm._e(),(_vm.seguroincendio.contrato.id !== null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(_vm.seguroincendio.areaconstruida > 0)?_c('base-input',{ref:"descontopercentualInput",attrs:{"tabindex":0,"label":"Percentual de Desconto","prepend-icon":"ni ni-user-run"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.descontopercentualFormatado),callback:function ($$v) {_vm.descontopercentualFormatado=$$v},expression:"descontopercentualFormatado"}}):_vm._e(),(_vm.seguroincendio.areaconstruida > 0)?_c('base-slider',{key:("slider-" + _vm.descontopercentualMin + "-" + _vm.descontopercentualMax),attrs:{"range":{
                    min: _vm.descontopercentualMin,
                    max: _vm.descontopercentualMax,
                  }},model:{value:(_vm.seguroincendio.descontopercentual),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "descontopercentual", $$v)},expression:"seguroincendio.descontopercentual"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.descontopercentual}})],1),_c('div',{staticClass:"col-md-4"},[(_vm.seguroincendio.areaconstruida > 0)?_c('base-input',{attrs:{"label":"Numero de Parcelas"}},[(_vm.seguroincendio.areaconstruida > 0)?_c('el-select',{ref:"seguroiprevnumeroparcelaInput",attrs:{"name":"seguroiprevnumeroparcela","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione..."},model:{value:(_vm.seguroincendio.seguroiprevnumeroparcela.id),callback:function ($$v) {_vm.$set(_vm.seguroincendio.seguroiprevnumeroparcela, "id", $$v)},expression:"seguroincendio.seguroiprevnumeroparcela.id"}},_vm._l((_vm.all_seguroiprevnumeroparcelas),function(single_seguroiprevnumeroparcela){return _c('el-option',{key:single_seguroiprevnumeroparcela.id,attrs:{"value":single_seguroiprevnumeroparcela.id,"label":_vm.preferencia &&
                        _vm.preferencia.seguroiprevnumeroparcela &&
                        single_seguroiprevnumeroparcela.id ===
                          _vm.preferencia.seguroiprevnumeroparcela.id
                          ? ("★ " + (single_seguroiprevnumeroparcela.name))
                          : single_seguroiprevnumeroparcela.name}})}),1):_vm._e()],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.seguroiprevnumeroparcela}}),_c('div',{ref:"display_seguroiprevnumeroparcela"})],1)]):_vm._e(),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[(
                  _vm.seguroincendio.clausulaservico.id !== null &&
                  _vm.seguroincendio.metodocalculo.id !== null
                )?_c('h3',{staticClass:"border-bottom"},[_vm._v(" Coberturas ")]):_vm._e()]),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"}):_vm._e(),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturaincendioInput",attrs:{"tabindex":0,"label":"Incêndio","prepend-icon":"fa fa-fire"},on:{"change":function($event){return _vm.calculacobertura(1)}},model:{value:(_vm.coberturaincendioFormatado),callback:function ($$v) {_vm.coberturaincendioFormatado=$$v},expression:"coberturaincendioFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturaincendioMin + "-" + _vm.coberturaincendioMax),attrs:{"range":{
                    min: _vm.coberturaincendioMin,
                    max: _vm.coberturaincendioMax,
                  }},model:{value:(_vm.seguroincendio.coberturaincendio),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturaincendio", $$v)},expression:"seguroincendio.coberturaincendio"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturaincendio}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturacivilInput",attrs:{"tabindex":0,"label":"Responsabilidade Civil","prepend-icon":"fa fa-users"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturacivilFormatado),callback:function ($$v) {_vm.coberturacivilFormatado=$$v},expression:"coberturacivilFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturacivilMin + "-" + _vm.coberturacivilMax),attrs:{"range":{ min: _vm.coberturacivilMin, max: _vm.coberturacivilMax }},model:{value:(_vm.seguroincendio.coberturacivil),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturacivil", $$v)},expression:"seguroincendio.coberturacivil"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturacivil}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturaconteudoInput",attrs:{"tabindex":0,"label":"Conteúdo","prepend-icon":"ni ni-app","readonly":_vm.coberturaconteudoMin == _vm.coberturaconteudoMax},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturaconteudoFormatado),callback:function ($$v) {_vm.coberturaconteudoFormatado=$$v},expression:"coberturaconteudoFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturaconteudoMin + "-" + _vm.coberturaconteudoMax),attrs:{"range":{
                    min: _vm.coberturaconteudoMin,
                    max: _vm.coberturaconteudoMax,
                  },"readonly":_vm.coberturaconteudoMin == _vm.coberturaconteudoMax},model:{value:(_vm.seguroincendio.coberturaconteudo),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturaconteudo", $$v)},expression:"seguroincendio.coberturaconteudo"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturaconteudo}})],1)]):_vm._e(),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturaeletricoInput",attrs:{"tabindex":0,"label":"Danos Elétricos","prepend-icon":"fa fa-bolt"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturaeletricoFormatado),callback:function ($$v) {_vm.coberturaeletricoFormatado=$$v},expression:"coberturaeletricoFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturaeletricoMin + "-" + _vm.coberturaeletricoMax),attrs:{"range":{
                    min: _vm.coberturaeletricoMin,
                    max: _vm.coberturaeletricoMax,
                  }},model:{value:(_vm.seguroincendio.coberturaeletrico),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturaeletrico", $$v)},expression:"seguroincendio.coberturaeletrico"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturaeletrico}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturaimpactoInput",attrs:{"tabindex":0,"label":"Impacto Veículos","prepend-icon":"fa fa-car"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturaimpactoFormatado),callback:function ($$v) {_vm.coberturaimpactoFormatado=$$v},expression:"coberturaimpactoFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturaimpactoMin + "-" + _vm.coberturaimpactoMax),attrs:{"range":{
                    min: _vm.coberturaimpactoMin,
                    max: _vm.coberturaimpactoMax,
                  }},model:{value:(_vm.seguroincendio.coberturaimpacto),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturaimpacto", $$v)},expression:"seguroincendio.coberturaimpacto"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturaimpacto}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturaaluguelInput",attrs:{"tabindex":0,"label":"Perda Aluguel","prepend-icon":"fa fa-home"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturaaluguelFormatado),callback:function ($$v) {_vm.coberturaaluguelFormatado=$$v},expression:"coberturaaluguelFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturaaluguelMin + "-" + _vm.coberturaaluguelMax),attrs:{"range":{
                    min: _vm.coberturaaluguelMin,
                    max: _vm.coberturaaluguelMax,
                  }},model:{value:(_vm.seguroincendio.coberturaaluguel),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturaaluguel", $$v)},expression:"seguroincendio.coberturaaluguel"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturaaluguel}})],1)]):_vm._e(),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"coberturavendavalInput",attrs:{"tabindex":0,"label":"Vendaval","prepend-icon":"fa fa-ellipsis-v"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturavendavalFormatado),callback:function ($$v) {_vm.coberturavendavalFormatado=$$v},expression:"coberturavendavalFormatado"}}),_c('base-slider',{key:("slider-" + _vm.coberturavendavalMin + "-" + _vm.coberturavendavalMax),attrs:{"range":{
                    min: _vm.coberturavendavalMin,
                    max: _vm.coberturavendavalMax,
                  }},model:{value:(_vm.seguroincendio.coberturavendaval),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturavendaval", $$v)},expression:"seguroincendio.coberturavendaval"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturavendaval}})],1),_c('div',{staticClass:"col-md-4"},[(_vm.coberturatumultoMax > 0)?_c('base-input',{ref:"coberturatumultoInput",attrs:{"tabindex":0,"label":"Tumulto","prepend-icon":"ni ni-user-run"},on:{"change":function($event){return _vm.calculaparcela()}},model:{value:(_vm.coberturatumultoFormatado),callback:function ($$v) {_vm.coberturatumultoFormatado=$$v},expression:"coberturatumultoFormatado"}}):_vm._e(),(_vm.coberturatumultoMax > 0)?_c('base-slider',{key:("slider-" + _vm.coberturatumultoMin + "-" + _vm.coberturatumultoMax),attrs:{"range":{
                    min: _vm.coberturatumultoMin,
                    max: _vm.coberturatumultoMax,
                  }},model:{value:(_vm.seguroincendio.coberturatumulto),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "coberturatumulto", $$v)},expression:"seguroincendio.coberturatumulto"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.coberturatumulto}})],1)]):_vm._e(),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[(
                  _vm.seguroincendio.clausulaservico.id !== null &&
                  _vm.seguroincendio.metodocalculo.id !== null
                )?_c('h3',{staticClass:"border-bottom"},[_vm._v(" Contratação ")]):_vm._e()]),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"}):_vm._e(),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Deseja Contratar automaticamente?"}},[_c('base-switch',{ref:"autocontratacaoInput",staticClass:"mr-1",attrs:{"on-text":"Sim","off-text":"Não"},model:{value:(_vm.seguroincendio.autocontratacao),callback:function ($$v) {_vm.$set(_vm.seguroincendio, "autocontratacao", $$v)},expression:"seguroincendio.autocontratacao"}})],1)],1)]):_vm._e(),(
                _vm.seguroincendio.clausulaservico.id !== null &&
                _vm.seguroincendio.metodocalculo.id !== null
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(false)?_c('base-input',{ref:"valorestimadoInput",attrs:{"tabindex":0,"label":"Estimativa do custo (pode sofrer alteração)","prepend-icon":"ni ni-user-run"},model:{value:(_vm.valorestimadoFormatado),callback:function ($$v) {_vm.valorestimadoFormatado=$$v},expression:"valorestimadoFormatado"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.valorestimado}})],1)]):_vm._e(),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[_c('h3',{staticClass:"border-bottom"})]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.isSubmitting}},[_vm._v(" Salvar Alterações ")])],1)])])])],1)]),_c('Modal',{staticClass:"modal-overlay",attrs:{"show":_vm.isModalContratoVisible},on:{"update:show":function($event){_vm.isModalContratoVisible=$event}}},[_c('AddContrato',{attrs:{"isInsideModal":true,"insideModalOnSave":'getContrato'},on:{"close-modal":function($event){_vm.isModalContratoVisible = false},"callFunction":_vm.handleFunctionCall}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }