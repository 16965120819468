<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Apólices</h3>
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addSeguroincendio()">
                <span class="btn-inner--icon"
                  ><i class="fas fa-user-edit"></i
                ></span>
                <span class="btn-inner--text">Novo Orçamento</span>
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Pesquisar..."
                clearable
              />
            </div>
          </div>

          <!--
            <ul class="nav nav-tabs nav-fill">    
              <li class="nav-item">
                <a v-bind:class="[navitem_at == 0 ? 'active' : '', 'nav-link']" href="#" @click="navitem_at=0;getList()">Todos</a>
              </li>    
              <li class="nav-item">
                <a v-bind:class="[navitem_at == 1 ? 'active' : '', 'nav-link']" href="#" @click="navitem_at=1;getList()">Orçamento</a>
              </li>    
              <li class="nav-item">
                <a v-bind:class="[navitem_at == 2 ? 'active' : '', 'nav-link']" href="#" @click="navitem_at=2;getList()">Contratados</a>
              </li></ul>
-->
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="seguroincendios"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            
                        <el-table-column
                          label="Locação"
                          min-width="50px"
                          prop="contrato.codigo"
                        />
                        <el-table-column
                          label="Detalhes"
                          min-width="150px"
                        >
                          <template slot-scope="scope">
                            <div>
                              <div><strong> {{ scope.row.enderecotxt }}</strong></div>
                              <div><small><strong>Proponente:</strong></small> {{ scope.row.proponentenome }}</div>
                              <div><small><strong>Beneficiário:</strong></small> {{ scope.row.beneficiarionomes }}</div>
                              <div v-if="scope.row.numeroapolice>0"><small><strong>Apólice: </strong>{{ scope.row.numeroapolice }}</small></div>
                            </div>
                          </template>
                        </el-table-column>

                        <el-table-column
                          label="Status"
                          min-width="60px"
                        >
                          <template slot-scope="scope">
                              <div>{{scope.row.seguroistatu.status}}</div>
                              <div v-if="scope.row.seguroistatu.id==4">{{scope.row.parcela_qtd}}x de R$ {{scope.row.parcela_valor}}</div>
                          </template>
                        </el-table-column>
            <el-table-column min-width="85px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                    
            <el-tooltip content="Baixar Proposta" placement="top" v-if="row.numeroproposta != 0 && row.propostapdf == 1">
                <a
                type="text"
                @click="baixarArquivoProposta(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
                >
                <i class="far fa-file-pdf"></i>
                </a>
            </el-tooltip>    
            <el-tooltip content="Baixar Apólice" placement="top" v-if="row.numeroapolice != 0 && row.apolicepdf == 1">
                <a
                type="text"
                @click="baixarArquivo(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
                >
                <i class="fas fa-file-pdf"></i>
                </a>
            </el-tooltip>    
            <el-tooltip content="Contratar Seguro Incendio" placement="top" v-if="row.seguroistatu.id == 2">
                <a
                type="text"
                @click="contratarSeguroincendio(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
                >
                <i class="fas fa-handshake"></i>
                </a>
            </el-tooltip>    
            <el-tooltip content="Cancelar Apólice" placement="top" v-if="row.numeroapolice != 0">
                <a
                type="text"
                @click="deleteSeguroincendio(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
                >
                <i class="fas fa-ban"></i>
                </a>
            </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ total ? from + 1 : 0 }} até {{ to }} de um total de 
              {{ total }} registros.

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

    data() {
    return {
      query: null,
      seguroincendios: [],
      navitem_at: 0,
      navitem_filtros: [{},{contratado: 0},{contratado: 1}],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

    computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          
          ...({seguroistatu_id: 4 }),
                ...(this.navitem_filtros[this.navitem_at] ? this.navitem_filtros[this.navitem_at] : {}),
          ...(this.query ? { proponentenome: this.query } : {}),
          ...(this.query ? { 'contrato.codigo': this.query } : {}),
          ...(this.query ? { beneficiarionomes: this.query } : {}),
          ...(this.query ? { enderecotxt: this.query } : {}),
          ...(this.query ? { numeroapolice: this.query } : {}),
          ...(this.query ? { numeroproposta: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: "contrato,seguroistatu",
      };
      await this.$store.dispatch("seguroincendios/list", params).then(() => {
        this.seguroincendios = this.$store.getters["seguroincendios/list"];
        this.total = this.$store.getters["seguroincendios/listTotal"];
      });
      this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

        async baixarArquivoProposta(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch('seguroincendios/baixarArquivo', ['proposta', row.numeroproposta]);
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: 'application/pdf' });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, '_blank');
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
     async baixarArquivo(row) {
      try {
        // Chama a ação Vuex 'baixarApolice' diretamente
        const resposta = await this.$store.dispatch('seguroincendios/baixarArquivo', ['apolice', row.numeroapolice]);
        // Crie um Blob com o conteúdo do PDF
        const blob = new Blob([resposta], { type: 'application/pdf' });
        // Crie uma URL temporária para o Blob
        const blobUrl = URL.createObjectURL(blob);
        // Abra o PDF em uma nova janela ou guia
        window.open(blobUrl, '_blank');
      } catch (erro) {
        // Lidar com erros, como usuário não autenticado
        //console.error("Erro ao baixar a apólice:", erro);
      }
    },
     contratarSeguroincendio(row) {
      this.$router.push({
        name: "Add Seguroicontratar",
        params: { seguroincendio_id: row.id },
      });
    },async deleteSeguroincendio(row) {
  if (this.$isDemo == 1 && ["1", "2"].includes(row.id)) {
    this.$notify({
      type: "danger",
      message: "Você não esta habilitado para alterar dados de seguroincendios.",
    });
    return;
  }
  try {
    const confirmation = await swal.fire({
      title: `Cancelar esta apólice?`,
      type: "question",
      input: "text",
      inputPlaceholder: "Digite o Motivo do cancelamento",
      inputAttributes: {
        maxlength: "100",
        autocapitalize: "off"
      },
      inputValidator: (value) => {
        if (!value || value.length<5) {
          return "Você precisa digitar um motivo!";
        }
      },
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "Sim, Cancelar!",
      cancelButtonText: "Não, Manter Apólice",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-warning",
      }
    });

    if (confirmation.isConfirmed === true) {
      var seguroicancelar= {
        type: "seguroicancelars",
        motivo: confirmation.value,
            seguroincendio: {
                type: "seguroincendios",
                id: row.id
                },
        relationshipNames: ["seguroincendio"]
      };
      
      await this.$store.dispatch("seguroicancelars/add", seguroicancelar);
      await this.getList();
      this.$notify({
        type: "success",
        message: "Apólice Enviada para Cancelamento.",
      });
    }
  } catch (error) {
    if (error.response.data.errors[0]) {
      this.$notify({
        type: "danger",
        message: "This Tag still has associated Items.",
      });
    } else {
      this.$notify({
        type: "danger",
        message: "Oops, Algo deu errado!",
      });
    }
  }
},
    addSeguroincendio() {
      this.$router.push({ name: "Add Seguroincendio" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
