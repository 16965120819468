var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-8"},[_c('h2',{staticClass:"mb-0 ml-4"},[_vm._v(" "+_vm._s(this.$route.params && this.$route.params.id ? "Editar Cliente" : "Novo Cliente")+" ")])]),(!_vm.isInsideModal)?_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":_vm.goBack}},[_vm._v("Voltar para lista")])],1):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[_c('h3',{staticClass:"border-bottom"},[_vm._v("Dados Gerais:")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Tipo de Cliente"}},[_c('el-select',{ref:"pessoavinculoInput",attrs:{"name":"pessoavinculo","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione..."},model:{value:(_vm.cliente.pessoavinculo.id),callback:function ($$v) {_vm.$set(_vm.cliente.pessoavinculo, "id", $$v)},expression:"cliente.pessoavinculo.id"}},_vm._l((_vm.all_pessoavinculos),function(single_pessoavinculo){return _c('el-option',{key:single_pessoavinculo.id,attrs:{"value":single_pessoavinculo.id,"label":_vm.preferencia &&
                        _vm.preferencia.pessoavinculo &&
                        single_pessoavinculo.id ===
                          _vm.preferencia.pessoavinculo.id
                          ? ("★ " + (single_pessoavinculo.name))
                          : single_pessoavinculo.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.pessoavinculo}}),_c('div',{ref:"display_pessoavinculo"})],1),_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"codclienteInput",attrs:{"tabindex":0,"label":"Cod. do Cliente","prepend-icon":"fas fa-user","labelClasses":'form-control-label text-light'},model:{value:(_vm.cliente.codcliente),callback:function ($$v) {_vm.$set(_vm.cliente, "codcliente", $$v)},expression:"cliente.codcliente"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.codcliente}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask()),expression:"mask()"}],ref:"cpfcnpjInput",attrs:{"tabindex":0,"label":"CPF/CNPJ","prepend-icon":"fas fa-user"},on:{"change":function($event){return _vm.selecionapessoajuridicatipo()}},model:{value:(_vm.cliente.cpfcnpj),callback:function ($$v) {_vm.$set(_vm.cliente, "cpfcnpj", $$v)},expression:"cliente.cpfcnpj"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cpfcnpj}})],1),_c('div',{staticClass:"col-md-4"},[(_vm.cliente.tipopessoa.id != 1)?_c('base-input',{attrs:{"label":"Tipo Empresa","disabled":_vm.cliente.tipopessoa.id == 1 &&
                    _vm.cliente.pessoajuridicatipo.id == 4}},[(_vm.cliente.tipopessoa.id != 1)?_c('el-select',{ref:"pessoajuridicatipoInput",attrs:{"name":"pessoajuridicatipo","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":_vm.cliente.tipopessoa.id == 1 &&
                      _vm.cliente.pessoajuridicatipo.id == 4},model:{value:(_vm.cliente.pessoajuridicatipo.id),callback:function ($$v) {_vm.$set(_vm.cliente.pessoajuridicatipo, "id", $$v)},expression:"cliente.pessoajuridicatipo.id"}},_vm._l((_vm.all_pessoajuridicatipos),function(single_pessoajuridicatipo){return _c('el-option',{key:single_pessoajuridicatipo.id,attrs:{"value":single_pessoajuridicatipo.id,"label":_vm.preferencia &&
                        _vm.preferencia.pessoajuridicatipo &&
                        single_pessoajuridicatipo.id ===
                          _vm.preferencia.pessoajuridicatipo.id
                          ? ("★ " + (single_pessoajuridicatipo.name))
                          : single_pessoajuridicatipo.name}})}),1):_vm._e()],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.pessoajuridicatipo}}),_c('div',{ref:"display_pessoajuridicatipo"})],1)]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('base-input',{ref:"nomeInput",attrs:{"tabindex":0,"label":"Nome","prepend-icon":"fas fa-user"},model:{value:(_vm.cliente.nome),callback:function ($$v) {_vm.$set(_vm.cliente, "nome", $$v)},expression:"cliente.nome"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.nome}})],1),(_vm.cliente.pessoavinculo.id != '1')?_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Data de Nascimento"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"tabindex":0,"config":{
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      altInput: true,
                      altFormat: 'd/m/Y',
                    }},model:{value:(_vm.cliente.datanascimento),callback:function ($$v) {_vm.$set(_vm.cliente, "datanascimento", $$v)},expression:"cliente.datanascimento"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.datanascimento}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(_vm.cliente.tipopessoa.id == null)?_c('base-input',{attrs:{"label":"Tipo","disabled":true}},[(_vm.cliente.tipopessoa.id == null)?_c('el-select',{ref:"tipopessoaInput",attrs:{"name":"tipopessoa","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":true},on:{"change":function($event){return _vm.selecionapessoajuridicatipo()}},model:{value:(_vm.cliente.tipopessoa.id),callback:function ($$v) {_vm.$set(_vm.cliente.tipopessoa, "id", $$v)},expression:"cliente.tipopessoa.id"}},_vm._l((_vm.all_tipopessoas),function(single_tipopessoa){return _c('el-option',{key:single_tipopessoa.id,attrs:{"value":single_tipopessoa.id,"label":_vm.preferencia &&
                        _vm.preferencia.tipopessoa &&
                        single_tipopessoa.id === _vm.preferencia.tipopessoa.id
                          ? ("★ " + (single_tipopessoa.name))
                          : single_tipopessoa.name}})}),1):_vm._e()],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tipopessoa}}),_c('div',{ref:"display_tipopessoa"})],1)]),(_vm.cliente.pessoavinculo.id != 1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Estado Civil","disabled":_vm.cliente.pessoavinculo.id == 1}},[_c('el-select',{ref:"estadocivilInput",attrs:{"name":"estadocivil","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":_vm.cliente.pessoavinculo.id == 1},model:{value:(_vm.cliente.estadocivil.id),callback:function ($$v) {_vm.$set(_vm.cliente.estadocivil, "id", $$v)},expression:"cliente.estadocivil.id"}},_vm._l((_vm.all_estadocivils),function(single_estadocivil){return _c('el-option',{key:single_estadocivil.id,attrs:{"value":single_estadocivil.id,"label":_vm.preferencia &&
                        _vm.preferencia.estadocivil &&
                        single_estadocivil.id === _vm.preferencia.estadocivil.id
                          ? ("★ " + (single_estadocivil.name))
                          : single_estadocivil.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.estadocivil}}),_c('div',{ref:"display_estadocivil"})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Sexo","disabled":_vm.cliente.pessoavinculo.id == 1}},[_c('el-select',{ref:"pessoasexoInput",attrs:{"name":"pessoasexo","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":_vm.cliente.pessoavinculo.id == 1},model:{value:(_vm.cliente.pessoasexo.id),callback:function ($$v) {_vm.$set(_vm.cliente.pessoasexo, "id", $$v)},expression:"cliente.pessoasexo.id"}},_vm._l((_vm.all_pessoasexos),function(single_pessoasexo){return _c('el-option',{key:single_pessoasexo.id,attrs:{"value":single_pessoasexo.id,"label":_vm.preferencia &&
                        _vm.preferencia.pessoasexo &&
                        single_pessoasexo.id === _vm.preferencia.pessoasexo.id
                          ? ("★ " + (single_pessoasexo.name))
                          : single_pessoasexo.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.pessoasexo}}),_c('div',{ref:"display_pessoasexo"})],1)]):_vm._e(),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[(
                  _vm.cliente.pessoavinculo.id == 3 ||
                  _vm.cliente.pessoavinculo.id == 2
                )?_c('h3',{staticClass:"border-bottom"},[_vm._v(" Contato ")]):_vm._e()]),(
                _vm.cliente.pessoavinculo.id == 3 || _vm.cliente.pessoavinculo.id == 2
              )?_c('div',{staticClass:"row"}):_vm._e(),(
                _vm.cliente.pessoavinculo.id == 3 || _vm.cliente.pessoavinculo.id == 2
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('base-input',{ref:"emailInput",attrs:{"tabindex":0,"label":"E-mail","prepend-icon":"fas fa-user","disabled":_vm.cliente.pessoavinculo.id == 1},model:{value:(_vm.cliente.email),callback:function ($$v) {_vm.$set(_vm.cliente, "email", $$v)},expression:"cliente.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"Tipo de Telefone","disabled":_vm.cliente.pessoavinculo.id == 1,"labelClasses":'form-control-label text-light'}},[_c('el-select',{ref:"tipotelefoneInput",attrs:{"name":"tipotelefone","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":_vm.cliente.pessoavinculo.id == 1},on:{"change":function($event){return _vm.changetipotel()}},model:{value:(_vm.cliente.tipotelefone.id),callback:function ($$v) {_vm.$set(_vm.cliente.tipotelefone, "id", $$v)},expression:"cliente.tipotelefone.id"}},_vm._l((_vm.all_tipotelefones),function(single_tipotelefone){return _c('el-option',{key:single_tipotelefone.id,attrs:{"value":single_tipotelefone.id,"label":_vm.preferencia &&
                        _vm.preferencia.tipotelefone &&
                        single_tipotelefone.id === _vm.preferencia.tipotelefone.id
                          ? ("★ " + (single_tipotelefone.name))
                          : single_tipotelefone.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tipotelefone}}),_c('div',{ref:"display_tipotelefone"})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masktel()),expression:"masktel()"}],ref:"telefoneInput",attrs:{"tabindex":0,"label":"Telefone","prepend-icon":"fas fa-user","labelClasses":'form-control-label text-light',"disabled":_vm.cliente.tipotelefone.id == 4 ||
                    _vm.cliente.tipotelefone.id < 1 ||
                    _vm.cliente.pessoavinculo.id == 1},model:{value:(_vm.cliente.telefone),callback:function ($$v) {_vm.$set(_vm.cliente, "telefone", $$v)},expression:"cliente.telefone"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.telefone}})],1)]):_vm._e(),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[(
                  _vm.cliente.pessoavinculo.id == 3 ||
                  _vm.cliente.pessoavinculo.id == 1
                )?_c('h3',{staticClass:"border-bottom"},[_vm._v(" Endereço ")]):_vm._e()]),(
                _vm.cliente.pessoavinculo.id == 3 || _vm.cliente.pessoavinculo.id == 1
              )?_c('div',{staticClass:"row"}):_vm._e(),(
                _vm.cliente.pessoavinculo.id == 3 || _vm.cliente.pessoavinculo.id == 1
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(
                    _vm.cliente.pessoavinculo.id == 1 ||
                    _vm.cliente.pessoavinculo.id == 3
                  )?_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###-###'),expression:"'##.###-###'"}],ref:"cepInput",attrs:{"tabindex":0,"label":"CEP","prepend-icon":"fas fa-user"},on:{"change":function($event){return _vm.buscarcep()}},model:{value:(_vm.cliente.cep),callback:function ($$v) {_vm.$set(_vm.cliente, "cep", $$v)},expression:"cliente.cep"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cep}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"tipo logradouro","disabled":(_vm.apiValidationErrors.cep === null &&
                      _vm.cliente.logradourotipo.id !== null) ||
                    _vm.cliente.pessoavinculo.id == 2}},[_c('el-select',{ref:"logradourotipoInput",attrs:{"name":"logradourotipo","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":(_vm.apiValidationErrors.cep === null &&
                        _vm.cliente.logradourotipo.id !== null) ||
                      _vm.cliente.pessoavinculo.id == 2},model:{value:(_vm.cliente.logradourotipo.id),callback:function ($$v) {_vm.$set(_vm.cliente.logradourotipo, "id", $$v)},expression:"cliente.logradourotipo.id"}},_vm._l((_vm.all_logradourotipos),function(single_logradourotipo){return _c('el-option',{key:single_logradourotipo.id,attrs:{"value":single_logradourotipo.id,"label":_vm.preferencia &&
                        _vm.preferencia.logradourotipo &&
                        single_logradourotipo.id ===
                          _vm.preferencia.logradourotipo.id
                          ? ("★ " + (single_logradourotipo.name))
                          : single_logradourotipo.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.logradourotipo}}),_c('div',{ref:"display_logradourotipo"})],1)]):_vm._e(),(
                _vm.cliente.pessoavinculo.id == 3 || _vm.cliente.pessoavinculo.id == 1
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(
                    _vm.cliente.pessoavinculo.id == 1 ||
                    _vm.cliente.pessoavinculo.id == 3
                  )?_c('base-input',{ref:"logradouroInput",attrs:{"tabindex":0,"label":"Logradouro","prepend-icon":"fas fa-user","readonly":_vm.apiValidationErrors.cep === null &&
                    _vm.cliente.logradouro.length > 3},model:{value:(_vm.cliente.logradouro),callback:function ($$v) {_vm.$set(_vm.cliente, "logradouro", $$v)},expression:"cliente.logradouro"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.logradouro}})],1),_c('div',{staticClass:"col-md-4"},[(
                    _vm.cliente.pessoavinculo.id == 1 ||
                    _vm.cliente.pessoavinculo.id == 3
                  )?_c('base-input',{ref:"bairroInput",attrs:{"tabindex":0,"label":"bairro","prepend-icon":"fas fa-user","readonly":_vm.apiValidationErrors.cep === null &&
                    _vm.cliente.bairro.length > 3},model:{value:(_vm.cliente.bairro),callback:function ($$v) {_vm.$set(_vm.cliente, "bairro", $$v)},expression:"cliente.bairro"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.bairro}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"label":"cidade","disabled":(_vm.apiValidationErrors.cep === null &&
                      _vm.cliente.municipio.id !== null) ||
                    _vm.cliente.pessoavinculo.id == 2}},[_c('el-select',{ref:"municipioInput",attrs:{"name":"municipio","tabindex":0,"filterable":"","prepend-icon":"fas fa-user","placeholder":"Selecione...","disabled":(_vm.apiValidationErrors.cep === null &&
                        _vm.cliente.municipio.id !== null) ||
                      _vm.cliente.pessoavinculo.id == 2},model:{value:(_vm.cliente.municipio.id),callback:function ($$v) {_vm.$set(_vm.cliente.municipio, "id", $$v)},expression:"cliente.municipio.id"}},_vm._l((_vm.all_municipios),function(single_municipio){return _c('el-option',{key:single_municipio.id,attrs:{"value":single_municipio.id,"label":_vm.preferencia &&
                        _vm.preferencia.municipio &&
                        single_municipio.id === _vm.preferencia.municipio.id
                          ? ("★ " + (single_municipio.name))
                          : single_municipio.name}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.municipio}}),_c('div',{ref:"display_municipio"})],1)]):_vm._e(),(
                _vm.cliente.pessoavinculo.id == 3 || _vm.cliente.pessoavinculo.id == 1
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(
                    _vm.cliente.pessoavinculo.id == 1 ||
                    _vm.cliente.pessoavinculo.id == 3
                  )?_c('base-input',{ref:"numeroInput",attrs:{"tabindex":0,"label":"nº","prepend-icon":"fas fa-user"},model:{value:(_vm.numeroFormatado),callback:function ($$v) {_vm.numeroFormatado=$$v},expression:"numeroFormatado"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.numero}})],1),_c('div',{staticClass:"col-md-4"},[(
                    _vm.cliente.pessoavinculo.id == 1 ||
                    _vm.cliente.pessoavinculo.id == 3
                  )?_c('base-input',{ref:"complementoInput",attrs:{"tabindex":0,"label":"Complemento","prepend-icon":"fas fa-user"},model:{value:(_vm.cliente.complemento),callback:function ($$v) {_vm.$set(_vm.cliente, "complemento", $$v)},expression:"cliente.complemento"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.complemento}})],1)]):_vm._e(),_c('div',{staticClass:"card-subtitle mv-4 mt-4"},[_c('h3',{staticClass:"border-bottom"})]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.isSubmitting}},[_vm._v(" "+_vm._s(this.$route.params && this.$route.params.id ? "Salvar Alterações" : "Salvar Cliente")+" ")])],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }