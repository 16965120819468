import service from '@/store/services/interesses-service';

const state = {
  list: {},
  interesse: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, interesse) => {
    state.interesse = interesse;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((interesse) => { commit('SET_RESOURCE', interesse); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((interesse) => { commit('SET_RESOURCE', interesse); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((interesse) => { commit('SET_RESOURCE', interesse); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  interesse: state => state.interesse,
  dropdown: (state) => {
    return state.list.map(interesse => ({
      id: interesse.id,
      name: interesse.nome
    }));
  }
};

const interesses = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default interesses;
