<template>
  <div class="card">
    <div class="card-header">
      <h1>Editar Perfil</h1>
    </div>
    <div class="card-body">
      <form ref="profile_form" @submit.prevent="handleProfileUpdate">
       
        <validation-error :errors="apiValidationErrors.attachment" />
        <base-input
          label="Name"
          prepend-icon="fas fa-user"
          placeholder="Your name"
          v-model="user.name"
        />
        <validation-error :errors="apiValidationErrors.name" />
        <base-input
          label="Email"
          prepend-icon="fas fa-envelope"
          placeholder="Email"
          v-model="user.email"
        />
        <validation-error :errors="apiValidationErrors.email" />
        <div class="my-4">
          <base-button
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
          >
            Salvar
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "UserEditCard",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
  },

  mixins: [formMixin],

  props: {
    user: Object,
  },

  data() {
    return {
    };
  },

  methods: {
    async handleProfileUpdate() {
      if (this.$isDemo == 1 && ["1", "2", "3"].includes(this.user.id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default users.",
        });
        return;
      } else
        try {
          await this.$store.dispatch("profile/update", this.user);
          this.resetApiValidation();

          this.$notify({
            type: "success",
            message: "Profile updated successfully.",
          });

          await this.$store.getters["profile/me"];
        } catch (error) {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
          this.setApiValidation(error.response.data.errors);
        }
    },
  },
};
</script>
