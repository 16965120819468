import { render, staticRenderFns } from "./AddSeguroiformapagtoPage.vue?vue&type=template&id=ac955176&"
import script from "./AddSeguroiformapagtoPage.vue?vue&type=script&lang=js&"
export * from "./AddSeguroiformapagtoPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports