<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h2 class="mb-0 ml-4">Editar Fatura Extrato</h2>
              </div>
              <div class="col-4 text-right" v-if="!isInsideModal">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Voltar para lista</base-button
                >
              </div>
            </div>
            <div class="card-body"></div>
            <div class="card-body">
              <div class="card-subtitle mv-4 mt-4">
                <h3 class="border-bottom"></h3>
              </div>

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Salvar Alterações
                </base-button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from "@/components/BaseSlider";
import Modal from "@/components/Modal.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from "vue-the-mask";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    insideModalOnSave: {
      type: String,
      required: false,
      default: null,
    },
  },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,

      faturaextrato: {
        type: "faturaextratos",
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        : ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {},
  watch: {},
  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("faturaextratos/get", id);

        let apiData = this.$store.getters["faturaextratos/faturaextrato"];

        for (let key in apiData) {
          if (apiData[key] === null && this.faturaextrato[key]?.id == null) {
            apiData[key] = this.faturaextrato[key];
          }
        }
        this.faturaextrato = apiData;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
      // Verifica se o valor termina com um ponto
      let sulfix = "";
      if (valor.toString().endsWith(".")) {
        sulfix = ",";
      }

      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split(".")[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais,
      });
      return valorFormatado.toString() + sulfix;
    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
      let valorFormatado = valorBrasileiro
        .replace(/\./g, "")
        .replace(/,/g, ".");
      // Verifica se o último caractere é uma vírgula
      return valorFormatado;
    },

    goBack() {
      this.$router.push({ name: "List Faturaextratos" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }

      //this.faturaextrato.excerpt = this.faturaextrato.description;
      /**
      this.faturaextrato.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.faturaextrato.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar = Object.assign({}, this.faturaextrato);

        if (dados_enviar.created_at) {
          delete dados_enviar.created_at;
        }
        if (dados_enviar.updated_at) {
          delete dados_enviar.updated_at;
        }
        if (dados_enviar.deleted_at) {
          delete dados_enviar.deleted_at;
        }
        if (dados_enviar.tenants) {
          delete dados_enviar.tenants;
        }

        if (!this.$route.params.id) {
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("faturaextratos/add", dados_enviar);
          this.faturaextrato = await this.$store.getters[
            "faturaextratos/faturaextrato"
          ];
        } else {
          //atualizar registro
          await this.$store.dispatch("faturaextratos/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("faturaextratos/upload", {
            faturaextrato: this.faturaextrato,
            image: this.file,
          });

          this.faturaextrato.image = await this.$store.getters[
            "faturaextratos/url"
          ];

          await this.$store.dispatch(
            "faturaextratos/update",
            this.faturaextrato
          );
          this.faturaextrato = await this.$store.getters[
            "faturaextratos/Faturaextrato"
          ];
        }

        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Fatura Extrato Adicionado com sucesso.",
        });

        if (!this.isInsideModal) {
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        } else {
          //fecha modal
          this.$emit("close-modal");
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if (this.insideModalOnSave !== null) {
            this.$emit("callFunction", {
              functionName: this.insideModalOnSave,
              args: [this.faturaextrato.id],
            });
          }
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.faturaextrato.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch(
            "faturaextratos/destroy",
            this.faturaextrato.id
          );
        }

        this.faturaextrato.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },

  mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll("span");

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute("tabindex");
    });

    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
