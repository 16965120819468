import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/faturas`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .get(`${url}/faturas/${id}`, options)
    .then(response => {
      let fatura = jsona.deserialize(response.data);
      delete fatura.links;
      return fatura;
    });
}

function add(fatura) {
  const payload = jsona.serialize({
    stuff: fatura,
    includeNames: ["categories"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  //.post(`${url}/faturas`, payload, options)
  return axios
    .post(`${url}/faturas`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(fatura) {
  const payload = jsona.serialize({
    stuff: fatura,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  return axios
    .patch(`${url}/faturas/${fatura.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/faturas/${id}`, options);
}
function downloadPDF(tipo, arquivo) {
  return axios.get(`${url}/downloads/${tipo}/${arquivo}.pdf`, { responseType: 'blob' }).then((response) => {
    return response.data;
  });
}
/*
function upload(fatura, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/faturas/${fatura.id}/image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}
export default {
  list,
  get,
  add,
  update,
  destroy,
  upload
};
*/
export default {
  list,
  get,
  add,
  update,
  destroy,
  downloadPDF
};