import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/preferencias?include=municipios,clausulaservico,seguroiprevnumeroparcela`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .get(`${url}/preferencias/${id}?include=municipios,clausulaservico,seguroiprevnumeroparcela`, options)
    .then(response => {
      let preferencia = jsona.deserialize(response.data);
      delete preferencia.links;
      return preferencia;
    });
}

function add(preferencia) {
  const payload = jsona.serialize({
    stuff: preferencia,
    includeNames: ["categories"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  //.post(`${url}/preferencias?include=clausulaservico,seguroiprevnumeroparcela`, payload, options)
  return axios
    .post(`${url}/preferencias?include=municipios,clausulaservico,seguroiprevnumeroparcela`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(preferencia) {
  const payload = jsona.serialize({
    stuff: preferencia,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  return axios
    .patch(`${url}/preferencias/${preferencia.id}?include=municipios,clausulaservico,seguroiprevnumeroparcela`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/preferencias/${id}`, options);
}
function downloadPDF(tipo, arquivo) {
  return axios.get(`${url}/downloads/${tipo}/${arquivo}.pdf`, { responseType: 'blob' }).then((response) => {
    return response.data;
  });
}
function integracao(licenca) {
  const payload = jsona.serialize({
    stuff: licenca,
    includeNames: ["licenca"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.post(`${url}/integracoes/licenca`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}
function getintegracao() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.get(`${url}/integracoes/licenca`, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}
/*
function upload(preferencia, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/preferencias/${preferencia.id}/image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}
export default {
  list,
  get,
  add,
  update,
  destroy,
  upload
};
*/
export default {
  list,
  get,
  add,
  update,
  destroy,
  downloadPDF,
  integracao,
  getintegracao
};