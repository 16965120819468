<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-6 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h2 class="mb-0 ml-4">Editar Grupo</h2>
            </div>
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <base-input
                label="Name"
                prepend-icon="fas fa-user"
                v-model="role.name"
              />
              <base-input
                label="Comentário"
                prepend-icon="fas fa-user"
                v-model="role.comentario"
              />
              <validation-error :errors="apiValidationErrors.name" />


              <card>
                <div slot="header" class="row align-items-center">
                  <div class="col-8">
                    <h2 class="mb-0 ml-4">Permissões</h2>
                    <!--
                    <div class="card-body">
                      <div v-for="permission in allPermissions" :key="permission.id" class="permission">
                        <input
                          type="checkbox"
                          :id="'perm-' + permission.id"
                          :checked="role.permissions.some(perm => perm.id === permission.id)"
                          @change="togglePermission(role, permission)"
                        >
                        <label :for="'perm-' + permission.id">{{ permission.name }}</label>
                      </div>
                    </div>
                    -->
                    <div class="card-body">
                        <table>
                          <tbody v-if="categorianame != 'Label' && categorianame != 'Sistema Frontend'" style="margin-bottom:30px;" v-for="(categorias, categorianame) in groupedPermissions" :key="categorianame">
                                <tr><td colspan="5"><h4>{{ categorianame }}:</h4></td></tr>
                                <tr v-for="(Allpermissoes, label) in categorias" :key="label">
                                  <td style="min-width:200px;"><h5>{{ label }}</h5></td>
                                  <td style="min-width:40px;" class="permission" v-for="permission in Allpermissoes" :key="permission.id">
                                    
                                      <input
                                        type="checkbox"
                                        :id="'perm-' + permission.id"
                                        :checked="role.permissions.some(perm => perm.id === permission.id)"
                                        @change="togglePermission(role, permission)"
                                         :title="permission.tipo"
                                      >
                                      <label :for="'perm-' + permission.id" :class="{[permission.icon]: true}" :title="permission.tipo" ></label>
                                  </td>
                                </tr>
                                <tr><td colspan="5"><p/><p/></td></tr>
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </card>
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Atualizar Grupo
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>

      <div class="col-xl-6 order-xl-2">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h2 class="mb-0 ml-4">Usuários do Grupo</h2>
            </div>
          </div>
        </card>
      </div>
    </div>

  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
  },

  data() {
    return {
      role: {
        type: "roles",
        name: null,
        permissions: []
      },
      allPermissions:[],      
      relationshipNames: ["permissions"]
    };
  },
  created() {
    this.get();
    this.getAllPermissions();
  },
  computed: {
    groupedPermissions() {
      var tipos_lab={
        'view': 'Ver',
        'create': 'Criar',
        'edit': 'Editar',
        'delete': 'Deletar'
      };
      var tipos_icon={
        'view': 'fas fa-eye',
        'create': 'fas fa-plus',
        'edit': 'fas fa-pen',
        'delete': 'fas fa-trash',
      };

      var translatelabel={
        'users': {'traducao': 'Usuários', 'categoria':'Configurações'},
        'roles': {'traducao': 'Grupos', 'categoria':'Configurações'},
        'permissions': {'traducao': 'Permissões', 'categoria':'Sistema Frontend'},
        'empresas': {'traducao': 'Empresas', 'categoria':'Suporte'},
        'tenants': {'traducao': 'Tenants', 'categoria':'Suporte'},
        'apolicestatus': {'traducao': 'Status de Apólice', 'categoria':'Label'},
        'seguradoras': {'traducao': 'Seguradoras', 'categoria':'Label'},
        'logradourotipos': {'traducao': 'Tipo de Logradouro', 'categoria': 'Label'},
        'tipocontratos': {'traducao': 'Tipo de contrato', 'categoria': 'Label'},
        'tipopessoas': {'traducao': 'Tipo de Pessoa', 'categoria': 'Label'},
        'clausulaservicos': {'traducao': 'Tipo de Clausula de Serviço', 'categoria': 'Label'},
        'seguroistatus': {'traducao': 'Status do Seguro', 'categoria': 'Label'},
        'situcontratos': {'traducao': 'Situação do Contrato', 'categoria': 'Label'},
        'municipios': {'traducao': 'Municipios', 'categoria': 'Label'},
        'tipotelefones': {'traducao': 'Tipo de Telefone', 'categoria': 'Label'},
        'requesttipos': {'traducao': 'Tipo de Requisição', 'categoria': 'Label'},
        'seguroiformapagtos': {'traducao': 'Formas de Pagamento', 'categoria': 'Label'},
        'estadocivils': {'traducao': 'Estado civil', 'categoria': 'Label'},
        'pessoasexos': {'traducao': 'Sexo', 'categoria': 'Label'},
        'pessoavinculos': {'traducao': 'Vinclulo de Cliente', 'categoria': 'Label'},
        'pessoajuridicatipos': {'traducao': 'Tipo de PJ', 'categoria': 'Label'},
        'seguroiprevnumeroparcelas': {'traducao': 'Numero de Parcelas', 'categoria': 'Label'},
        'imoveltipos': {'traducao': 'Tipo de imóvel', 'categoria': 'Label'},
        'metodocalculos': {'traducao': 'método de calculo', 'categoria': 'Label'},
        'clientes': {'traducao': 'Clientes', 'categoria': 'Cadastro'},
        'imovels': {'traducao': 'Imóveis', 'categoria': 'Cadastro'},
        'seguroiparcelamentos': {'traducao': 'Parcela de Seguro Cotado', 'categoria': 'Sistema Frontend'},
        'contratos': {'traducao': 'Locações', 'categoria': 'Cadastro'},
        'seguroincendios': {'traducao': 'Seguro Incendio', 'categoria': 'Seguro Incendio'},
        'apirequests': {'traducao': 'Requisições API', 'categoria': 'Sistema Backend'},
        'seguroicontratars': {'traducao': 'Contrata Seguro', 'categoria': 'Seguro Incendio'},
        'seguroicancelars': {'traducao': 'Cancelar Seguro', 'categoria': 'Seguro Incendio'},
        'preferencias': {'traducao': 'Preferencias', 'categoria': 'Configurações'},
        'faturas': {'traducao': 'Fatura', 'categoria': 'Financeiro'},
        'faturaextratos': {'traducao': 'Extrato', 'categoria': 'Financeiro'},

        
        'categories': {'traducao': 'Categorias', 'categoria':'remover'},
      }
      this.allPermissions.forEach(permission =>{
        // Extrai 'tipo' e 'label' da propriedade 'name' usando destructuring após split
        const [tipo, label] = permission.name.split(' ');
        // Definindo 'tipo' e 'label' de volta no objeto permission para uso posterior
        permission.tipo = tipos_lab[tipo];
        permission.icon = tipos_icon[tipo];
        if(translatelabel[label]){
          permission.label = translatelabel[label]['traducao'];
          permission.categoria = translatelabel[label]['categoria'];
        }else{
          permission.label = label;
          permission.categoria = 'Não Categorizado';
        }
      });

      return this.allPermissions.reduce((groups, permission) => {
        
        if (!groups[permission.categoria]) {
          groups[permission.categoria] = {};
        }
        if (!groups[permission.categoria][permission.label]) {
          groups[permission.categoria][permission.label] = [];
        }

        groups[permission.categoria][permission.label].push(permission);
        return groups;
      }, {});
    }
  },

  methods: {
    togglePermission(role, permission) {
      // Verifique se a permissão já está presente
      const permIndex = role.permissions.findIndex(perm => perm.id === permission.id);
      
      if (permIndex === -1) {
        // Permissão não está presente, adiciona-la
        role.permissions.push(permission);
      } else {
        // Permissão está presente, removê-la
        role.permissions.splice(permIndex, 1);
      }
    },
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("roles/get", id);
        this.role = this.$store.getters["roles/role"];
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },
    async getAllPermissions(){
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
        };
        await this.$store.dispatch("permissions/list", params);
        this.allPermissions = this.$store.getters["permissions/list"];
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },
    goBack() {
      this.$router.push({ name: "List Roles" });
    },

    async handleSubmit() {
      if (
        this.$isDemo == 1 &&
        ["1", "2", "3"].includes(this.$route.params.id)
      ) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default roles.",
        });
        return;
      }
      try {
        await this.$store.dispatch("roles/update", this.role);

        this.$notify({
          type: "success",
          message: "Role updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
