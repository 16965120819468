import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/clientes?include=pessoavinculo,tipopessoa,pessoajuridicatipo,estadocivil,pessoasexo,tipotelefone,logradourotipo,municipio`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .get(`${url}/clientes/${id}?include=pessoavinculo,tipopessoa,pessoajuridicatipo,estadocivil,pessoasexo,tipotelefone,logradourotipo,municipio`, options)
    .then(response => {
      let cliente = jsona.deserialize(response.data);
      delete cliente.links;
      return cliente;
    });
}

function add(cliente) {
  const payload = jsona.serialize({
    stuff: cliente,
    includeNames: ["categories"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  //.post(`${url}/clientes?include=pessoavinculo,tipopessoa,pessoajuridicatipo,estadocivil,pessoasexo,tipotelefone,logradourotipo,municipio`, payload, options)
  return axios
    .post(`${url}/clientes?include=pessoavinculo,tipopessoa,pessoajuridicatipo,estadocivil,pessoasexo,tipotelefone,logradourotipo,municipio`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(cliente) {
  const payload = jsona.serialize({
    stuff: cliente,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  return axios
    .patch(`${url}/clientes/${cliente.id}?include=pessoavinculo,tipopessoa,pessoajuridicatipo,estadocivil,pessoasexo,tipotelefone,logradourotipo,municipio`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}


function estadocivilupdate(cliente) {
  const payload = jsona.serialize({
    stuff: cliente,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .patch(`${url}/clientes/UpdateEstadoCivil/${cliente.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/clientes/${id}`, options);
}
function downloadPDF(tipo, arquivo) {
  return axios.get(`${url}/downloads/${tipo}/${arquivo}.pdf`, { responseType: 'blob' }).then((response) => {
    return response.data;
  });
}
/*
function upload(cliente, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/clientes/${cliente.id}/image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}
export default {
  list,
  get,
  add,
  update,
  destroy,
  upload
};
*/
export default {
  list,
  get,
  add,
  update,
  estadocivilupdate,
  destroy,
  downloadPDF
};