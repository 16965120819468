import service from '@/store/services/imovels-service';

const state = {
  list: {},
  imovel: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, imovel) => {
    state.imovel = imovel;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((imovel) => { commit('SET_RESOURCE', imovel); });
  },

  baixarArquivo({ commit, dispatch }, params) {
    // Chama a função do serviço para baixar o PDF
      return service.downloadPDF(params[0],params[1])
        .then((resposta) => {
          // Manipular a resposta, se necessário
          //console.log("PDF baixado com sucesso!", resposta);
          return resposta;
        })
        .catch((erro) => {
          // Lidar com erros, como usuário não autenticado
          console.error("Erro ao baixar a apólice:", erro);
          throw erro;
        });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((imovel) => { commit('SET_RESOURCE', imovel); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((imovel) => { commit('SET_RESOURCE', imovel); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  imovel: state => state.imovel,
  dropdown: (state) => {
    return state.list.map(imovel => ({
      id: imovel.id,
      name: imovel.codimovel + ": " + imovel.logradouro + " nº " + imovel.numero + " - " + imovel.bairro,
      codimovel: imovel.codimovel,
      proprietarios: imovel.proprietarios,
      cep: imovel.cep,
      logradourotipo_id: imovel.logradourotipo?.id,
      logradouro: imovel.logradouro,
      bairro: imovel.bairro,
      municipio_id: imovel.municipio?.id,
      numero: imovel.numero,
      complemento: imovel.complemento
    }));
  }
};

const imovels = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default imovels;
