<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h2 class="mb-0 ml-4">Adicionar novo Lista de Municipios</h2>
            </div>
            <div class="col-4 text-right"  v-if="!isInsideModal" >
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Voltar para lista</base-button
              >
            </div>
          </div>
          <div class="card-body">
              
                </div>
                <div class="card-body">
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom"></h3></div>
                
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="nameInput"
        label="Municipio"
        prepend-icon="fas fa-user"
        v-model="municipio.name"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.name" />
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="ufInput"
        label="Estado"
        prepend-icon="fas fa-user"
        v-model="municipio.uf"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.uf" />
</div>
            </div>
        
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Adicionar Lista de Municipios
                </base-button>
              </div>
          </div>
          </form>
        </card>
      </div>
    </div>
  
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from '@/components/BaseSlider';
import Modal from '@/components/Modal.vue';
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from 'vue-the-mask';
import axios from 'axios';




export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
      isInsideModal: {
        type: Boolean,
        default: false,
      },
      insideModalOnSave: {
        type: String,
        required: false,
        default: null
      }
    },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,
      
      
      
      
      
      municipio: {
        type: "municipios",
        
        name:'',
        uf:'',
        
        
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        : ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  created() {
    
  },

  methods: {
    
    
    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
        // Verifica se o valor termina com um ponto
        let sulfix='';
        if (valor.toString().endsWith('.')) {
          sulfix=',';
        }
        
      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split('.')[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais
      });
      return (valorFormatado.toString()+sulfix);

    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
        let valorFormatado = valorBrasileiro.replace(/\./g, '').replace(/,/g, '.');
        // Verifica se o último caractere é uma vírgula
        return (valorFormatado);
    },

    goBack() {
      this.$router.push({ name: "List Municipios" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }
      
      //this.municipio.excerpt = this.municipio.description;
      /**
      this.municipio.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.municipio.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar=Object.assign({}, this.municipio);
        
        




        if(dados_enviar.created_at){delete dados_enviar.created_at;}
        if(dados_enviar.updated_at){delete dados_enviar.updated_at;}
        if(dados_enviar.deleted_at){delete dados_enviar.deleted_at;}
        if(dados_enviar.tenants){delete dados_enviar.tenants;}

        if(!this.$route.params.id){
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("municipios/add", dados_enviar);
          this.municipio = await this.$store.getters["municipios/municipio"];
        }else{
          //atualizar registro
          await this.$store.dispatch("municipios/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("municipios/upload", {
            municipio: this.municipio,
            image: this.file,
          });

          this.municipio.image = await this.$store.getters["municipios/url"];

          await this.$store.dispatch("municipios/update", this.municipio);
          this.municipio = await this.$store.getters["municipios/Municipio"];
        }

        
        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Lista de Municipios Adicionado com sucesso.",
        });

        if(!this.isInsideModal){
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        }else{
          //fecha modal
          this.$emit('close-modal');
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if(this.insideModalOnSave!==null){
            this.$emit('callFunction', { functionName: this.insideModalOnSave, args: [this.municipio.id] });
          }
        }


      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.municipio.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch("municipios/destroy", this.municipio.id);
        }

        this.municipio.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },
  
    mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll('span');

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute('tabindex');
    });


    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
