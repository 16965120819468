<template>
  <div class="card card-profile">


    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between">
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="text-center">
        <h5 class="h3">
          {{user.name}}
          
          
        </h5>
        <div class="h5 font-weight-300">
          <span class="font-weight-light">{{user.email}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: Object,
  },
};
</script>
<style></style>
