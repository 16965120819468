import service from '@/store/services/apolicestatus-service';

const state = {
  list: {},
  apolicestatu: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, apolicestatu) => {
    state.apolicestatu = apolicestatu;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((apolicestatu) => { commit('SET_RESOURCE', apolicestatu); });
  },

  baixarArquivo({ commit, dispatch }, params) {
    // Chama a função do serviço para baixar o PDF
      return service.downloadPDF(params[0],params[1])
        .then((resposta) => {
          // Manipular a resposta, se necessário
          //console.log("PDF baixado com sucesso!", resposta);
          return resposta;
        })
        .catch((erro) => {
          // Lidar com erros, como usuário não autenticado
          console.error("Erro ao baixar a apólice:", erro);
          throw erro;
        });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((apolicestatu) => { commit('SET_RESOURCE', apolicestatu); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((apolicestatu) => { commit('SET_RESOURCE', apolicestatu); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  apolicestatu: state => state.apolicestatu,
  dropdown: (state) => {
    return state.list.map(apolicestatu => ({
      id: apolicestatu.id,
      name: apolicestatu.status,
      status: apolicestatu.status
    }));
  }
};

const apolicestatus = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default apolicestatus;
