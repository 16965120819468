import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/interesses`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .get(`${url}/interesses/${id}`, options)
    .then(response => {
      let interesse = jsona.deserialize(response.data);
      delete interesse.links;
      return interesse;
    });
}

function add(interesse) {
  const payload = jsona.serialize({
    stuff: interesse,
    includeNames: ["categories"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  //.post(`${url}/interesses?include=proprietarios,logradourotipo,municipio`, payload, options)
  return axios
    .post(`${url}/interesses`, payload, options)
    .then(response => {
        if (response.data) {
          return jsona.deserialize(response.data); // Supondo que jsona seja uma biblioteca/instância válida
        } else {
          return true; // Não há dados, então retornamos true como sinal de sucesso sem dados
        }
      }
  );
}

function update(interesse) {
  const payload = jsona.serialize({
    stuff: interesse,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  
  return axios
    .patch(`${url}/interesses/${interesse.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/interesses/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy
};