<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <!--ul class="nav nav-footer">
        <li class="nav-item">
          <a
            href=""
            class="nav-link"
            target="_blank"
            rel="noopener"
            >LINK 01</a
          >
        </li>
        <li class="nav-item">
          <a
            href=""
            class="nav-link"
            target="_blank"
            rel="noopener"
            >LINK 02</a
          >
        </li>
        <li class="nav-item">
          <a
            href=""
            class="nav-link"
            target="_blank"
            rel="noopener"
            >SOBRE NÓS</a
          >
        </li>
        <li class="nav-item">
          <a
            href=""
            class="nav-link"
            target="_blank"
            rel="noopener"
            >BLOG</a
          >
        </li>
      </ul -->
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-right text-muted">
          © {{ year }}, SISPAD
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
