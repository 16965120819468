<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h2 class="mb-0 ml-4">Adicionar novo Preferências</h2>
            </div>
            <div class="col-4 text-right"  v-if="!isInsideModal" >
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Voltar para lista</base-button
              >
            </div>
          </div>
          <div class="card-body">
              
                </div>
                <div class="card-body">
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom">Seguro incêndio</h3></div>
                
                    <div class="row">
                <div class="col-md-4">              
    <base-input label="Serviços de Assistência 24h" >
    <el-select
    name="clausulaservico"
    ref="clausulaservicoInput"
    :tabindex="0"
    v-model="preferencia.clausulaservico.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    
    
    >
    <el-option
        v-for="single_clausulaservico in all_clausulaservicos"
        :key="single_clausulaservico.id"
        :value="single_clausulaservico.id"
        :label="preferencia && preferencia.clausulaservico && single_clausulaservico.id === preferencia.clausulaservico.id ? `★ ${single_clausulaservico.name}` : single_clausulaservico.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.clausulaservico" />
    <div ref="display_clausulaservico"></div>
</div><div class="col-md-4">
    <base-input label="Obrigatoriamente?">
    <base-switch
        on-text="Sim" off-text="Não"
        class="mr-1"
        v-model="preferencia.clausulaservicoobrigatorio"
        ref="clausulaservicoobrigatorioInput"
    ></base-switch>
    </base-input>
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="descontopercentualminInput"
        label="Percentual de Desconto Minimo"
        prepend-icon="ni ni-user-run"
        v-model="descontopercentualminFormatado"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.descontopercentualmin" />
</div><div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="descontopercentualmaxInput"
        label="Percentual de Desconto Máximo"
        prepend-icon="ni ni-user-run"
        v-model="descontopercentualmaxFormatado"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.descontopercentualmax" />
</div><div class="col-md-4">
        <base-input
        :tabindex="0"
        ref="descontopercentualpadInput"
        label="Percentual de Desconto Padrão"
        prepend-icon="ni ni-user-run"
        v-model="descontopercentualpadFormatado"
        
        
        />
        
        <validation-error :errors="apiValidationErrors.descontopercentualpad" />
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">              
    <base-input label="Numero de Parcelas" >
    <el-select
    name="seguroiprevnumeroparcela"
    ref="seguroiprevnumeroparcelaInput"
    :tabindex="0"
    v-model="preferencia.seguroiprevnumeroparcela.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    
    
    >
    <el-option
        v-for="single_seguroiprevnumeroparcela in all_seguroiprevnumeroparcelas"
        :key="single_seguroiprevnumeroparcela.id"
        :value="single_seguroiprevnumeroparcela.id"
        :label="preferencia && preferencia.seguroiprevnumeroparcela && single_seguroiprevnumeroparcela.id === preferencia.seguroiprevnumeroparcela.id ? `★ ${single_seguroiprevnumeroparcela.name}` : single_seguroiprevnumeroparcela.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.seguroiprevnumeroparcela" />
    <div ref="display_seguroiprevnumeroparcela"></div>
</div><div class="col-md-4">
    <base-input label="Obrigatoriamente?">
    <base-switch
        on-text="Sim" off-text="Não"
        class="mr-1"
        v-model="preferencia.seguroiprevnumeroparcelaobrigatorio"
        ref="seguroiprevnumeroparcelaobrigatorioInput"
    ></base-switch>
    </base-input>
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">
    <base-input label="Contratar automaticamente?">
    <base-switch
        on-text="Sim" off-text="Não"
        class="mr-1"
        v-model="preferencia.autocontratacao"
        ref="autocontratacaoInput"
    ></base-switch>
    </base-input>
</div><div class="col-md-4">
    <base-input label="Obrigatoriamente?">
    <base-switch
        on-text="Sim" off-text="Não"
        class="mr-1"
        v-model="preferencia.autocontratacaoobrigatorio"
        ref="autocontratacaoobrigatorioInput"
    ></base-switch>
    </base-input>
</div></div>
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom"></h3></div>
                <div class="row">
            </div>
        
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Adicionar Preferências
                </base-button>
              </div>
          </div>
          </form>
        </card>
      </div>
    </div>
  
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from '@/components/BaseSlider';
import Modal from '@/components/Modal.vue';
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from 'vue-the-mask';
import axios from 'axios';




export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
      isInsideModal: {
        type: Boolean,
        default: false,
      },
      insideModalOnSave: {
        type: String,
        required: false,
        default: null
      }
    },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,
      
      all_clausulaservicos: [],
      all_seguroiprevnumeroparcelas: [],
      
      
      
      preferencia: {
        type: "preferencias",
        
        clausulaservicoobrigatorio:'',
        descontopercentualmin:'',
        descontopercentualmax:'',
        descontopercentualpad:'',
        seguroiprevnumeroparcelaobrigatorio:'',
        autocontratacao:'',
        autocontratacaoobrigatorio:'',
        
            clausulaservico: {
                type: "clausulaservicos",
                id: null
                },
            seguroiprevnumeroparcela: {
                type: "seguroiprevnumeroparcelas",
                id: null
                },
        relationshipNames: ["clausulaservico","seguroiprevnumeroparcela"]
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["clausulaservico","seguroiprevnumeroparcela"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    
                            descontopercentualminFormatado: {
                                get() {
                                    return this.numToBr(this.preferencia.descontopercentualmin);
                                },
                                set(valor) {
                                    this.preferencia.descontopercentualmin = this.numToUs(valor);
                                }
                            },
                            descontopercentualmaxFormatado: {
                                get() {
                                    return this.numToBr(this.preferencia.descontopercentualmax);
                                },
                                set(valor) {
                                    this.preferencia.descontopercentualmax = this.numToUs(valor);
                                }
                            },
                            descontopercentualpadFormatado: {
                                get() {
                                    return this.numToBr(this.preferencia.descontopercentualpad);
                                },
                                set(valor) {
                                    this.preferencia.descontopercentualpad = this.numToUs(valor);
                                }
                            },
  },
  watch: {
    
  },
  created() {
    
    this.getClausulaservico();
    this.getSeguroiprevnumeroparcela();
  },

  methods: {
    
async getClausulaservico(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.clausulaservicos_filter_itens ? this.clausulaservicos_filter_itens : {}),
        },
      };
      //if(this.all_clausulaservicos || Object.keys(this.all_clausulaservicos).lenght == undefined){
        await this.$store.dispatch("clausulaservicos/list", params);
        this.all_clausulaservicos = await this.$store.getters["clausulaservicos/dropdown"];
      //}
      

      if (this.all_clausulaservicos && this.all_clausulaservicos.length) {
       // this.preferencia.clausulaservico.id = this.all_clausulaservicos[0].id;
        if(idn !== null){
            this.preferencia.clausulaservico.id = idn;            this.$refs.clausulaservicoInput.$emit('change', this.preferencia.clausulaservico.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getSeguroiprevnumeroparcela(idn = null) {
    try {
      //filter_route
      let params = {
        filter: {
            ...(this.seguroiprevnumeroparcelas_filter_itens ? this.seguroiprevnumeroparcelas_filter_itens : {}),
        },
      };
      //if(this.all_seguroiprevnumeroparcelas || Object.keys(this.all_seguroiprevnumeroparcelas).lenght == undefined){
        await this.$store.dispatch("seguroiprevnumeroparcelas/list", params);
        this.all_seguroiprevnumeroparcelas = await this.$store.getters["seguroiprevnumeroparcelas/dropdown"];
      //}
      

      if (this.all_seguroiprevnumeroparcelas && this.all_seguroiprevnumeroparcelas.length) {
       // this.preferencia.seguroiprevnumeroparcela.id = this.all_seguroiprevnumeroparcelas[0].id;
        if(idn !== null){
            this.preferencia.seguroiprevnumeroparcela.id = idn;            this.$refs.seguroiprevnumeroparcelaInput.$emit('change', this.preferencia.seguroiprevnumeroparcela.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
    
    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
        // Verifica se o valor termina com um ponto
        let sulfix='';
        if (valor.toString().endsWith('.')) {
          sulfix=',';
        }
        
      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split('.')[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais
      });
      return (valorFormatado.toString()+sulfix);

    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
        let valorFormatado = valorBrasileiro.replace(/\./g, '').replace(/,/g, '.');
        // Verifica se o último caractere é uma vírgula
        return (valorFormatado);
    },

    goBack() {
      this.$router.push({ name: "List Preferencias" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }
      
      //this.preferencia.excerpt = this.preferencia.description;
      /**
      this.preferencia.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.preferencia.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar=Object.assign({}, this.preferencia);
        
        if(dados_enviar.clausulaservico?.id==null){
          delete dados_enviar.clausulaservico;
        }
        if(dados_enviar.seguroiprevnumeroparcela?.id==null){
          delete dados_enviar.seguroiprevnumeroparcela;
        }
        




        if(dados_enviar.created_at){delete dados_enviar.created_at;}
        if(dados_enviar.updated_at){delete dados_enviar.updated_at;}
        if(dados_enviar.deleted_at){delete dados_enviar.deleted_at;}
        if(dados_enviar.tenants){delete dados_enviar.tenants;}

        if(!this.$route.params.id){
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("preferencias/add", dados_enviar);
          this.preferencia = await this.$store.getters["preferencias/preferencia"];
        }else{
          //atualizar registro
          await this.$store.dispatch("preferencias/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("preferencias/upload", {
            preferencia: this.preferencia,
            image: this.file,
          });

          this.preferencia.image = await this.$store.getters["preferencias/url"];

          await this.$store.dispatch("preferencias/update", this.preferencia);
          this.preferencia = await this.$store.getters["preferencias/Preferencia"];
        }

        
        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "Preferências Adicionado com sucesso.",
        });

        if(!this.isInsideModal){
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        }else{
          //fecha modal
          this.$emit('close-modal');
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if(this.insideModalOnSave!==null){
            this.$emit('callFunction', { functionName: this.insideModalOnSave, args: [this.preferencia.id] });
          }
        }


      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.preferencia.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch("preferencias/destroy", this.preferencia.id);
        }

        this.preferencia.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },
  
    mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll('span');

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute('tabindex');
    });


    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
