<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <form @submit.prevent="handleSubmit">
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Contratar Seguro Incendio</h3>
            </div>
            <div class="col-4 text-right"  v-if="!isInsideModal" >
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Voltar para lista</base-button
              >
            </div>
          </div>
          <div class="card-body">
              
                </div>
                <div class="card-body">
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom">Contratação</h3></div>
                
                    <div class="row">
                <div class="col-md-4">
        <input type="hidden" v-model="seguroicontratar.seguroincendio_id"
         />
        <div ref="display_seguroincendio_id"></div>
</div>
                        </div>
                    
                    <div class="row">
                <div class="col-md-4">              
    <base-input label="Parcelamento" >
    <el-select
    name="seguroiparcelamento"
    ref="seguroiparcelamentoInput"
    :tabindex="0"
    v-model="seguroicontratar.seguroiparcelamento.id"
    filterable
    prepend-icon="fas fa-user"
    placeholder="Selecione..."
    @change="this.exibirparcelas"
    
    >
    <el-option
        v-for="single_seguroiparcelamento in all_seguroiparcelamentos"
        :key="single_seguroiparcelamento.id"
        :value="single_seguroiparcelamento.id"
        :label="preferencia && preferencia.seguroiparcelamento && single_seguroiparcelamento.id === preferencia.seguroiparcelamento.id ? `★ ${single_seguroiparcelamento.name}` : single_seguroiparcelamento.name"
        
    >
    </el-option>
    </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.seguroiparcelamento" />
    <div ref="display_seguroiparcelamento"></div>
</div></div>
                    <div class="card-subtitle mv-4 mt-4"><h3 class="border-bottom"></h3></div>
                <div class="row">
            </div>
        
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  :disabled="isSubmitting"
                >
                  Contratar Seguro Incendio
                </base-button>
              </div>
          </div>
          </form>
        </card>
      </div>
    </div>
  
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseSlider from '@/components/BaseSlider';
import Modal from '@/components/Modal.vue';
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
flatpickr.localize(Portuguese);
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { mask } from 'vue-the-mask';
import axios from 'axios';




export default {
  layout: "DashboardLayout",
  directives: { mask },

  components: {
    
    Modal,
    HtmlEditor,
    BaseInput,
    BaseButton,
    BaseSlider,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
      isInsideModal: {
        type: Boolean,
        default: false,
      },
      insideModalOnSave: {
        type: String,
        required: false,
        default: null
      }
    },

  mixins: [formMixin],

  data() {
    return {
      isSubmitting: false,
      
      all_seguroincendios: [],
      all_seguroiparcelamentos: [],
      
      seguroiorcamento:null,
      
      seguroiparcelamentos_filter_itens: {seguroincendio_id: null},
      seguroicontratar: {
        type: "seguroicontratars",
        
        
            seguroincendio: {
                type: "seguroincendios",
                id: null
                },
            seguroiparcelamento: {
                type: "seguroiparcelamentos",
                id: null
                },
        relationshipNames: ["seguroincendio","seguroiparcelamento"]
      },
      /*
        status: "published",
        is_on_homepage: false,
        date_at: new Date(),
        image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["seguroincendio","seguroiparcelamento"]: ["category", "tags"],
        user: null,
      },*/
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  created() {
    this.getseguroincendio_id();
    
    this.getSeguroiparcelamento();
  },

  methods: {
    
                async getseguroincendio_id() {
                    try {
                      const id_seguroincendio_id = this.$route.params.seguroincendio_id;
                      this.seguroicontratar.seguroincendio.id=id_seguroincendio_id
                      if(id_seguroincendio_id==undefined){
                          this.$router.push({
                          name: "List Seguroincendios"
                        });
                      }                      
                    } catch (error) {
                      this.$notify({
                        type: "danger",
                        message: `Oops, something went wrong!`,
                      });
                      this.setApiValidation(error.response.data.errors);
                    }
                  }, 
                
async getSeguroincendio(idn = null) {
    try {
      
        
                        if (this.$route && this.$route.params && this.$route.params.seguroincendio_id) {
                            this.seguroiparcelamentos_filter_itens.seguroincendio_id = this.$route.params.seguroincendio_id;
                            if(idn == null && 'seguroiparcelamentos' == 'seguroincendios'){idn = this.$route.params.seguroincendio_id;}
                        }else{
                            delete this.seguroiparcelamentos_filter_itens.seguroincendio_id;
                        }
                        
      let params = {
        filter: {
            ...(this.seguroincendios_filter_itens ? this.seguroincendios_filter_itens : {}),
        },
      };
      //if(this.all_seguroincendios || Object.keys(this.all_seguroincendios).lenght == undefined){
        await this.$store.dispatch("seguroincendios/list", params);
        this.all_seguroincendios = await this.$store.getters["seguroincendios/dropdown"];
      //}
      

      if (this.all_seguroincendios && this.all_seguroincendios.length) {
       // this.seguroicontratar.seguroincendio.id = this.all_seguroincendios[0].id;
        if(idn !== null){
            this.seguroicontratar.seguroincendio.id = idn;            this.$refs.seguroincendioInput.$emit('change', this.seguroicontratar.seguroincendio.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
async getSeguroiparcelamento(idn = null) {
    try {
      
        
                        if (this.$route && this.$route.params && this.$route.params.seguroincendio_id) {
                            this.seguroiparcelamentos_filter_itens.seguroincendio_id = this.$route.params.seguroincendio_id;
                            if(idn == null && 'seguroiparcelamentos' == 'seguroincendios'){idn = this.$route.params.seguroincendio_id;}
                        }else{
                            delete this.seguroiparcelamentos_filter_itens.seguroincendio_id;
                        }
                        
      let params = {
        filter: {
            ...(this.seguroiparcelamentos_filter_itens ? this.seguroiparcelamentos_filter_itens : {}),
        },
      };
      //if(this.all_seguroiparcelamentos || Object.keys(this.all_seguroiparcelamentos).lenght == undefined){
        await this.$store.dispatch("seguroiparcelamentos/list", params);
        this.all_seguroiparcelamentos = await this.$store.getters["seguroiparcelamentos/dropdown"];
      //}
      

      if (this.all_seguroiparcelamentos && this.all_seguroiparcelamentos.length) {
       // this.seguroicontratar.seguroiparcelamento.id = this.all_seguroiparcelamentos[0].id;
        if(idn !== null){
            this.seguroicontratar.seguroiparcelamento.id = idn;            this.$refs.seguroiparcelamentoInput.$emit('change', this.seguroicontratar.seguroiparcelamento.id);
            
        }
      }
    } catch (error) {
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
},
    

    async getseguroincendio() {
  try {
    //coleta numero do seguro
    const id_seguroincendio = this.$route.params.seguroincendio;
    this.seguroicontratar.seguroincendio.id=id_seguroincendio
    if(id_seguroincendio==undefined){
        this.$router.push({
        name: "List Seguroiorcamentos"
      });
    }

    //parametros de busca
    let params = {
      sort:'qtdparcelas',
      filter: {
        seguroincendio_id: id_seguroincendio,
        codigo: this.seguroicontratar.seguroiformapagto.id,
      }
    };
    
    //remove seleção do campo parcelamento
    this.seguroicontratar.seguroiparcelamento.id=null;

    //busca forma de pagamento
    await this.$store.dispatch("seguroiparcelamentos/list", params);
    this.all_seguroiparcelamentos = await this.$store.getters["seguroiparcelamentos/dropdown"];
    if (this.all_seguroiparcelamentos && this.all_seguroiparcelamentos.length) {
    // this.seguroicontratar.seguroiparcelamento.id = this.all_seguroiformapagtos[0].id;
    }

  } catch (error) {
    this.$notify({
      type: "danger",
      message: `Oops, something went wrong!`,
    });
    this.setApiValidation(error.response.data.errors);
  }
},

async onmountedexe() {

  this.exibirdadoscotacao();   
},
async exibirdadoscotacao() {
 //dados a serem exibidos
 var dados_exibir='<table>';
 dados_exibir+="\n"+'<tr><td><b><!--imovel--></b></td></tr>';
 dados_exibir+="\n"+'<tr><td><!--proprietario--></td></tr>';
 dados_exibir+="\n"+'<tr><td><!--inquilino--> <i>(Inquilino)</i></td></tr>';
 dados_exibir+="\n"+'<tr><td><!--imoveltipo--></td></tr>';
 dados_exibir+="\n"+'<tr><td><!--tipocontrato--> - <!--situcontrato--></td></tr>';
 dados_exibir+="\n"+'<tr><td>Locado em <!--con_datainicio--> por R$ <!--valorlocacao--></td></tr>';
 dados_exibir+="\n"+'<tr><td><div><br></br></div></td></tr>';
 dados_exibir+="\n"+'<tr><td><div><b>SEGURO PORTO IMOBILIÁRIO:</b></div></td></tr>';
 dados_exibir+="\n"+'<tr><td>Vigencia de <!--datainicio--> até <!--datafim--></td></tr>';
 dados_exibir+="\n"+'<tr><td>Serviços: <!--clausulaservico--></td></tr>';
 dados_exibir+="\n"+'<tr><td>Coberturas:</td></tr><tr><td><ul>';
 dados_exibir+="\n"+'   <li>Incendio: <!--coberturaincendio--></li>';
 dados_exibir+="\n"+'   <li>Aluguel: <!--coberturaaluguel--></li>';
 dados_exibir+="\n"+'   <li>Civil: <!--coberturacivil--></li>';
 dados_exibir+="\n"+'   <li>Conteudo: <!--coberturaconteudo--></li>';
 dados_exibir+="\n"+'   <li>Elétrico: <!--coberturaeletrico--></li>';
 dados_exibir+="\n"+'   <li>Impacto: <!--coberturaimpacto--></li>';
 dados_exibir+="\n"+'   <li>Tumulto: <!--coberturatumulto--></li>';
 dados_exibir+="\n"+'   <li>Vendaval: <!--coberturavendaval--></li>';
 dados_exibir+="\n"+'</ul></td></tr>';
 dados_exibir+="\n"+'<tr><td><div><br></br></div></td></tr>';
 dados_exibir+='</table>';


 //PRELOAD
 var dados_exibir_at=dados_exibir.replace(/<td>.*?<\/td>/gs, "<td>Carregando...</td>");
 this.$refs.display_seguroincendio_id.innerHTML= dados_exibir_at;

 //CARREGA DADOS DO ORÇAMENTO
 await this.$store.dispatch(`seguroincendios/get`, this.seguroicontratar.seguroincendio.id);
 this.seguroiorcamento = this.$store.getters[`seguroincendios/seguroincendio`];
 
   //variaveis personalizadas
   var ex_imovel_endereco=this.seguroiorcamento.imovel.codimovel+': ';
       ex_imovel_endereco+=this.seguroiorcamento.imovel.logradouro;
       ex_imovel_endereco+=', '+this.seguroiorcamento.imovel.numero;
       if(this.seguroiorcamento.imovel.complemento!=null){
         ex_imovel_endereco+=' - '+this.seguroiorcamento.imovel.complemento;
       }
       ex_imovel_endereco+=' - '+this.seguroiorcamento.imovel.bairro;
   
   var ex_proprietarios='';
         this.seguroiorcamento.imovel.proprietarios.forEach(element => {
         ex_proprietarios+='<div>'+element.nome+' <i>(Proprietário)</i></div>';
       });
       
   var contrato_data_inicio=this.seguroiorcamento.contrato.datainicio.split('-').reverse().join('/');
   var vigencia_data_inicio=this.seguroiorcamento.datainicio.split('-').reverse().join('/');
   var vigencia_data_fim=this.seguroiorcamento.datafim.split('-').reverse().join('/');
   
   //substituições
   var dados_exibir_at=dados_exibir;
   dados_exibir_at=dados_exibir_at.replace('<!--imoveltipo-->', this.seguroiorcamento.imoveltipo.name);
   dados_exibir_at=dados_exibir_at.replace('<!--imovel-->', ex_imovel_endereco);
   dados_exibir_at=dados_exibir_at.replace('<!--tipocontrato-->', this.seguroiorcamento.tipocontrato.name);
   dados_exibir_at=dados_exibir_at.replace('<!--situcontrato-->', this.seguroiorcamento.situcontrato.name);
   dados_exibir_at=dados_exibir_at.replace('<!--proprietario-->', ex_proprietarios);
   dados_exibir_at=dados_exibir_at.replace('<!--inquilino-->', this.seguroiorcamento.inquilino.nome);
   dados_exibir_at=dados_exibir_at.replace('<!--con_datainicio-->', contrato_data_inicio);
   dados_exibir_at=dados_exibir_at.replace('<!--datainicio-->', vigencia_data_inicio);
   dados_exibir_at=dados_exibir_at.replace('<!--datafim-->', vigencia_data_fim);
   dados_exibir_at=dados_exibir_at.replace('<!--valorlocacao-->', this.seguroiorcamento.valorlocacao);
   dados_exibir_at=dados_exibir_at.replace('<!--clausulaservico-->', this.seguroiorcamento.clausulaservico.servico);

 
   dados_exibir_at=dados_exibir_at.replace('<!--coberturaincendio-->', this.seguroiorcamento.coberturaincendio);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturaaluguel-->', this.seguroiorcamento.coberturaaluguel);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturacivil-->', this.seguroiorcamento.coberturacivil);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturaconteudo-->', this.seguroiorcamento.coberturaconteudo);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturaeletrico-->', this.seguroiorcamento.coberturaeletrico);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturaimpacto-->', this.seguroiorcamento.coberturaimpacto);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturatumulto-->', this.seguroiorcamento.coberturatumulto);
   dados_exibir_at=dados_exibir_at.replace('<!--coberturavendaval-->', this.seguroiorcamento.coberturavendaval);


   //insere dados
   this.$refs.display_seguroincendio_id.innerHTML=dados_exibir_at;

}, 
async exibirparcelas(){
     //CARREGA DADOS DA PARCELA
     await this.$store.dispatch(`seguroiparcelamentos/get`, this.seguroicontratar.seguroiparcelamento.id);
     const parcelamento_value = this.$store.getters[`seguroiparcelamentos/seguroiparcelamento`];
       //exibir parcelas
       
       //VERIFICA DATA DE VENCIMENTO
           // Supondo que 'dataInicio' seja uma string no formato 'YYYY-MM-DD'
           var dataInicio = this.seguroiorcamento.datainicio; // Exemplo de data de início
 
           // Criação da variável 'dataVencimento' para o dia 15 do mês seguinte
           var dataVencimento = moment(dataInicio).add(1, 'months').date(15);
 
           var intervalo = 15 - 25; // Inicialização da variável 'intervalo'
 
           // Ajuste da variável 'intervalo' com base no mês de fevereiro
           if (moment(dataInicio).month() === 1) { // Fevereiro tem índice 1, pois Janeiro é 0
             intervalo += moment(dataInicio).isLeapYear() ? 29 : 28;
           } else {
             intervalo += 30;
           }
 
           intervalo += 1; // Adicionando 1 ao 'intervalo'
 
           // Criação da variável 'dataCorte'
           var dataCorte = moment(dataVencimento).subtract(intervalo, 'days');
 
           // Determinação da 'primeira_parcela'
           var primeiraParcela;
           if (moment(dataInicio).isBefore(dataCorte)) {
             primeiraParcela = dataVencimento;
           } else {
             primeiraParcela = moment(dataVencimento).add(1, 'months').date(15);
           }
 
           // Criação do array 'cobranca' e preenchimento com as datas das parcelas
           var cobranca = [];
           for (var i = 0; i < 12; i++) {
             cobranca.push(moment(primeiraParcela).add(i, 'months').format('DD/MM/YYYY'));
           }
 
 
       var dados_exibir='<table style="width:100%;">';
         dados_exibir+="\n"+'<tr>';
         dados_exibir+="\n"+'<th>#</th>';
         dados_exibir+="\n"+'<th>Venc.</th>';
         dados_exibir+="\n"+'<th>Valor</th>';
         dados_exibir+="\n"+'</tr>';
       var valor_at=0;
       for(let i=1; i<=parcelamento_value.qtdparcelas; i++){
         if(i==1){
           valor_at=parcelamento_value.valor1aparcela;
         }else{
           valor_at=parcelamento_value.valordemaisparcelas;
         }
         dados_exibir+="\n"+'<tr>';
         dados_exibir+="\n"+'<td>'+i+'º</td>';
         dados_exibir+="\n"+'<td>'+cobranca[(i-1)]+'</td>';
         dados_exibir+="\n"+'<td>'+valor_at+'</td>';
         dados_exibir+="\n"+'</tr>';
       }
       dados_exibir+='</table>';
       console.log('parcelas',parcelamento_value);
       //insere dados
       this.$refs.display_seguroiparcelamento.innerHTML=dados_exibir;
 

},
    /*
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
*/
    //Formata numero para formato brasileiro
    numToBr(valor) {
        // Verifica se o valor termina com um ponto
        let sulfix='';
        if (valor.toString().endsWith('.')) {
          sulfix=',';
        }
        
      // Encontrar a quantidade de dígitos decimais no número original
      const numDecimais = (valor.toString().split('.')[1] || "").length;
      let valorFormatado = Number(valor).toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: numDecimais,
        maximumFractionDigits: numDecimais
      });
      return (valorFormatado.toString()+sulfix);

    },
    //formata numero para formato americano
    numToUs(valorBrasileiro) {
        let valorFormatado = valorBrasileiro.replace(/\./g, '').replace(/,/g, '.');
        // Verifica se o último caractere é uma vírgula
        return (valorFormatado);
    },

    goBack() {
      this.$router.push({ name: "List Seguroincendios" });
    },
    removeImage() {
      this.image = null;
    },
    handleFunctionCall(payload) {
      const { functionName, args } = payload;
      if (functionName in this) {
        this[functionName](...args);
      } else {
        console.error(`Função ${functionName} não encontrada!`);
      }
    },

    async handleSubmit() {
      this.isSubmitting = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      if (this.user.roles[0].name === "member") {
        this.$notify({
          type: "danger",
          message: "Oops, you are not authorized to do this action.",
        });
        return;
      }
      
      //this.seguroicontratar.excerpt = this.seguroicontratar.description;
      /**
      this.seguroicontratar.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
      this.seguroicontratar.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      */
      try {
        var dados_enviar=Object.assign({}, this.seguroicontratar);
        
        if(dados_enviar.seguroiparcelamento?.id==null){
          delete dados_enviar.seguroiparcelamento;
        }
        




        if(dados_enviar.created_at){delete dados_enviar.created_at;}
        if(dados_enviar.updated_at){delete dados_enviar.updated_at;}
        if(dados_enviar.deleted_at){delete dados_enviar.deleted_at;}
        if(dados_enviar.tenants){delete dados_enviar.tenants;}

        if(!this.$route.params.id){
          //incluir novo registro
          delete dados_enviar.id;
          await this.$store.dispatch("seguroicontratars/add", dados_enviar);
          this.seguroicontratar = await this.$store.getters["seguroicontratars/seguroicontratar"];
        }else{
          //atualizar registro
          await this.$store.dispatch("seguroicontratars/update", dados_enviar);
        }

        if (this.file) {
          await this.$store.dispatch("seguroicontratars/upload", {
            seguroicontratar: this.seguroicontratar,
            image: this.file,
          });

          this.seguroicontratar.image = await this.$store.getters["seguroicontratars/url"];

          await this.$store.dispatch("seguroicontratars/update", this.seguroicontratar);
          this.seguroicontratar = await this.$store.getters["seguroicontratars/Seguroicontratar"];
        }

        
        this.isSubmitting = false;
        this.$notify({
          type: "success",
          message: "seguro contratado com sucesso.",
        });

        if(!this.isInsideModal){
          //retorna para a pagina anterior se não estiver dentro de um modal
          this.goBack();
        }else{
          //fecha modal
          this.$emit('close-modal');
          //executa função onsave (para recarregar elementos de um select por exemplo)
          if(this.insideModalOnSave!==null){
            this.$emit('callFunction', { functionName: this.insideModalOnSave, args: [this.seguroicontratar.id] });
          }
        }


      } catch (error) {
        this.isSubmitting = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.seguroicontratar.id && !this.$route.params.id) {
          //executa apenas em tela de inclusão onde não tem id definido na tela... em tela de edição não esecuta
          await this.$store.dispatch("seguroicontratars/destroy", this.seguroicontratar.id);
        }

        this.seguroicontratar.date_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },
  
    mounted() {
    // Obtenha todos os elementos span dentro do elemento pai
    const spans = this.$el.querySelectorAll('span');

    // Remova a propriedade tabindex de todos os elementos span
    spans.forEach((span) => {
      span.removeAttribute('tabindex');
    });


    //verifica a aexistencia da funcão onmontedexe
    if (typeof this.onmountedexe === "function") {
      this.onmountedexe();
    }
  },
};
</script>
