function addTawkToScript() {
  //console.log("Adicionando script Tawk.to...");
  var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = 'https://embed.tawk.to/659b5a3d8d261e1b5f5090e4/1hjjdh0pj';
  s1.charset = 'UTF-8';
  s1.setAttribute('crossorigin', '*');
  s1.onload = () => {
    //console.log("Script Tawk.to carregado com sucesso.");
  };
  s1.onerror = (e) => {
    //console.error("Erro ao carregar script Tawk.to:", e);
  };
  s0.parentNode.insertBefore(s1, s0);
}

export default {
  install(Vue, options) {
    // Adicione o script do Tawk.to ao carregar o aplicativo
    addTawkToScript();

    // Adicione um método global para configurar o Tawk.to
    Vue.prototype.$initializeTawkTo = (newOptions = {}) => {
      //console.log("Chamando $initializeTawkTo com newOptions:", newOptions);

      if (typeof window !== "undefined") {
        const config = { ...options, ...newOptions };

        const setupTawkAPI = () => {
          if (window.Tawk_API && window.Tawk_API.login) {
            //console.log("Tawk_API disponível. Configurando atributos do usuário...");
            const storedUserId = localStorage.getItem('tawk_user_id');
            const currentUserId = config.user.userId; // Substitua pelo id do usuário atual

            if (storedUserId !== currentUserId) {
              //window.Tawk_API.start();
              //efetuar login
              // Configure os atributos do usuário
              window.Tawk_API.login({
                'userId': config.user.userId,
                'name': config.user.name,
                'email': config.user.email,
                'hash': config.user.hash,
                'organization': config.user.organization
                // ... você pode adicionar mais atributos aqui
              }, function(error) {
                if (error) {
                  console.error("Erro ao configurar Tawk.to:", error);
                } else {
                  //console.log("Tawk.to configurado com sucesso:", config.user);
                  // Salve o novo ID do usuário
                  localStorage.setItem('tawk_user_id', config.user.userId); 
                  window.Tawk_API.showWidget();
                }
              });
            }

            // Verifique se a API refletiu os novos atributos
            //console.log("Atributos atuais no Tawk_API:", window.Tawk_API);
          } else {
            //console.log("Tawk_API ainda não disponível, tentando novamente em 500ms.");
            setTimeout(setupTawkAPI, 500);
          }
        };

        // Verifique se o Tawk_API está pronto e configure
        setupTawkAPI();
      }
    };
  }
};
